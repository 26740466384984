import {
    SET_USER_ORG,
    CREATE_ORG,
    CREATE_ORG_SUCCESS,
    CREATE_ORG_FAILURE,
    RESET_ORG_CREATION_STATE,
    UPDATE_ORG,
    UPDATE_ORG_SUCCESS,
    UPDATE_ORG_FAILURE,
    RESET_ORG_UPDATE_STATE,
    SET_PRIMARY_ORG,
    ADD_INVITE_TO_ORG,
    ADD_INVITE_TO_ORG_SUCCESS,
    ADD_INVITE_TO_ORG_FAILURE,
    GET_ORGS_COLLECTION,
    GET_ORGS_COLLECTION_SUCCESS,
    GET_ORGS_COLLECTION_FAILURE,
    GET_DEFAULT_ROLES_COLLECTION,
    GET_DEFAULT_ROLES_COLLECTION_SUCCESS,
    GET_DEFAULT_ROLES_COLLECTION_FAILURE,
    SET_ACTIVE_USER_ORG_SUCCESS,
    SET_ACTIVE_USER_ORG_FAILURE,
    SET_SELECTED_ORG,
    RESET_INVITE_ADDING_STATE,
    UPDATE_ORG_IMAGE,
    UPDATE_ORG_IMAGE_SUCCESS,
    UPDATE_ORG_IMAGE_FAILURE,
    RESEND_MANAGER_INVITE,
    RESEND_MANAGER_INVITE_SUCCESS,
    RESEND_MANAGER_INVITE_FAILURE,
    REMOVE_USER_FROM_ORG,
    VALIDATE_SHORT_CODE,
    SET_SHORT_CODE_VALIDATION_RESULT,
    ADD_TAG_MASTER_CAMERA,
    ADD_TAG_MASTER_CAMERA_SUCCESS,
    ADD_TAG_MASTER_CAMERA_FAILURE,
    UPDATE_TAG_MASTER_CAMERA,
    UPDATE_TAG_MASTER_CAMERA_SUCCESS,
    UPDATE_TAG_MASTER_CAMERA_FAILURE,
    DELETE_TAG_MASTER_CAMERA,
    DELETE_TAG_MASTER_CAMERA_SUCCESS,
    DELETE_TAG_MASTER_CAMERA_FAILURE,
    RESET_TAG_MASTER_SUCCESS_STATE
} from './types';

export const getOrgCollection = () => {
    return {
        type: GET_ORGS_COLLECTION
    };
};

export const getOrgCollectionFailure = error => {
    return {
        type: GET_ORGS_COLLECTION_FAILURE,
        payload: error
    };
};

export const getOrgCollectionSuccess = data => {
    return {
        type: GET_ORGS_COLLECTION_SUCCESS,
        payload: data
    };
};

export const getDefaultRoles = () => {
    return {
        type: GET_DEFAULT_ROLES_COLLECTION
    };
};

export const getDefaultRolesFailure = error => {
    return {
        type: GET_DEFAULT_ROLES_COLLECTION_FAILURE,
        payload: error
    };
};

export const getDefaultRolesSuccess = data => {
    return {
        type: GET_DEFAULT_ROLES_COLLECTION_SUCCESS,
        payload: data
    };
};

export const storeOrgData = org => {
    return {
        type: SET_USER_ORG,
        payload: org
    };
};

export const createNewOrg = ogrData => {
    return {
        type: CREATE_ORG,
        payload: ogrData
    };
};

export const createOrgSuccess = orgName => {
    return {
        type: CREATE_ORG_SUCCESS,
        payload: orgName
    };
};

export const createOrgFailure = error => {
    return {
        type: CREATE_ORG_FAILURE,
        payload: error
    };
};

export const resetOrgCreationState = () => {
    return {
        type: RESET_ORG_CREATION_STATE
    };
};

export const updateOrg = ogrData => {
    return {
        type: UPDATE_ORG,
        payload: ogrData
    };
};

export const updateOrgSuccess = orgName => {
    return {
        type: UPDATE_ORG_SUCCESS,
        payload: orgName
    };
};

export const updateOrgFailure = error => {
    return {
        type: UPDATE_ORG_FAILURE,
        payload: error
    };
};

export const resetOrgUpdateState = () => {
    return {
        type: RESET_ORG_UPDATE_STATE
    };
};

export const removeOrgUser = user => {
    return {
        type: REMOVE_USER_FROM_ORG,
        payload: user
    };
};

export const settingSelectedOrg = org => {
    return {
        type: SET_SELECTED_ORG,
        payload: org
    };
};

export const settingPrimaryOrg = org => {
    return {
        type: SET_PRIMARY_ORG,
        payload: org
    };
};

export const addInviteToOrg = inviteInfo => {
    return {
        type: ADD_INVITE_TO_ORG,
        payload: inviteInfo
    };
};

export const addInviteToOrgSuccess = email => {
    return {
        type: ADD_INVITE_TO_ORG_SUCCESS,
        payload: email
    };
};

export const addInviteToOrgFailure = error => {
    return {
        type: ADD_INVITE_TO_ORG_FAILURE,
        payload: error
    };
};

export const settingActiveOrgSuccess = org => {
    return {
        type: SET_ACTIVE_USER_ORG_SUCCESS,
        payload: org
    };
};

export const settingActiveOrgFailure = error => {
    return {
        type: SET_ACTIVE_USER_ORG_FAILURE,
        payload: error
    };
};

export const resetInviteAddingState = () => {
    return {
        type: RESET_INVITE_ADDING_STATE
    };
};

export const updateOrgImage = data => {
    return {
        type: UPDATE_ORG_IMAGE,
        payload: data
    };
};

export const updateOrgImageSuccess = downloadUrl => {
    return {
        type: UPDATE_ORG_IMAGE_SUCCESS,
        payload: downloadUrl
    };
};

export const updateOrgImageFailure = error => {
    return {
        type: UPDATE_ORG_IMAGE_FAILURE,
        payload: error
    };
};

export const resendManagerInvite = payload => {
    return {
        type: RESEND_MANAGER_INVITE,
        payload: payload
    };
};

export const resendManagerInviteSuccess = success => {
    return {
        type: RESEND_MANAGER_INVITE_SUCCESS,
        payload: success
    };
};

export const resendManagerInviteFailure = error => {
    return {
        type: RESEND_MANAGER_INVITE_FAILURE,
        payload: error
    };
};

export const validateShortCode = shortCode => ({
    type: VALIDATE_SHORT_CODE,
    payload: shortCode
});

export const setShortCodeValidationResult = (isValid, errorMessage) => ({
    type: SET_SHORT_CODE_VALIDATION_RESULT,
    payload: { isValid, errorMessage }
});

export const addTagMasterCamera = (orgId, cameraData) => ({
    type: ADD_TAG_MASTER_CAMERA,
    payload: { orgId, cameraData }
});

export const addTagMasterCameraSuccess = cameraData => ({
    type: ADD_TAG_MASTER_CAMERA_SUCCESS,
    payload: cameraData
});

export const addTagMasterCameraFailure = error => ({
    type: ADD_TAG_MASTER_CAMERA_FAILURE,
    payload: error
});

export const updateTagMasterCamera = (orgId, cameraData) => ({
    type: UPDATE_TAG_MASTER_CAMERA,
    payload: { orgId, cameraData }
});

export const updateTagMasterCameraSuccess = cameraData => ({
    type: UPDATE_TAG_MASTER_CAMERA_SUCCESS,
    payload: cameraData
});

export const updateTagMasterCameraFailure = error => ({
    type: UPDATE_TAG_MASTER_CAMERA_FAILURE,
    payload: error
});

export const deleteTagMasterCamera = (orgId, cameraId) => ({
    type: DELETE_TAG_MASTER_CAMERA,
    payload: { orgId, cameraId }
});

export const deleteTagMasterCameraSuccess = cameraId => ({
    type: DELETE_TAG_MASTER_CAMERA_SUCCESS,
    payload: cameraId
});

export const deleteTagMasterCameraFailure = error => ({
    type: DELETE_TAG_MASTER_CAMERA_FAILURE,
    payload: error
});

export const resetTagMasterSuccessState = {
    type: RESET_TAG_MASTER_SUCCESS_STATE
};
