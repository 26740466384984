import {
    FETCH_DEVICE_TYPES,
    FETCH_DEVICE_TYPES_SUCCESS,
    FETCH_DEVICE_TYPES_FAILURE,
    ADD_DEVICE_TYPE,
    ADD_DEVICE_TYPE_SUCCESS,
    ADD_DEVICE_TYPE_FAILURE
} from './types';

export const fetchDeviceTypes = () => ({ type: FETCH_DEVICE_TYPES });

export const fetchDeviceTypesSuccess = data => ({
    type: FETCH_DEVICE_TYPES_SUCCESS,
    payload: data
});

export const fetchDeviceTypesFailure = error => ({
    type: FETCH_DEVICE_TYPES_FAILURE,
    payload: error
});

export const addDeviceType = (key, value) => ({
    type: ADD_DEVICE_TYPE,
    payload: { key, value }
});

export const addDeviceTypeSuccess = data => ({
    type: ADD_DEVICE_TYPE_SUCCESS,
    payload: data
});

export const addDeviceTypeFailure = error => ({
    type: ADD_DEVICE_TYPE_FAILURE,
    payload: error
});
