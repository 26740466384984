import { takeLatest, put, call, all, fork, take, race } from 'redux-saga/effects';
import {
    collection,
    doc,
    updateDoc,
    arrayUnion,
    arrayRemove,
    query,
    getDocs,
    where
} from 'firebase/firestore';
import { db, rtdb, fsTimeStampNow } from 'config/firebase';

import { openSnackbar } from 'store/actions/Snackbar';
import {
    ADD_VEHICLE,
    ADD_VEHICLE_SUCCESS,
    DELETE_VEHICLE,
    DELETE_VEHICLE_SUCCESS,
    UPDATE_VEHICLE,
    UPDATE_VEHICLE_SUCCESS,
    VEHICLE_OPERATION_FAILURE
} from '../actions/types';
import { cleanDocument } from '../../utils/Helpers';
import { set, ref as rtdbRef, remove } from 'firebase/database';
import {
    createVehiclePlate,
    createVehiclePlateForRealtimeDatabase,
    isPlateUnique
} from '../../utils/helpers/vehicle.helper';
import { getDoc } from '@firebase/firestore';

const accessKeysCollectionRef = collection(db, 'access_keys');

const addVehicleToFirestore = async (vehicle, ownerId) => {
    const userDocRef = doc(db, 'users', ownerId);
    const userDocSnapshot = await getDoc(userDocRef);

    const userData = userDocSnapshot.data();

    const formattedVehicle = {
        country: vehicle.country,
        make: vehicle.make,
        model: vehicle.model,
        primary: true,
        state: vehicle.state,
        tag: vehicle.tag,
        updated_at: fsTimeStampNow()
    };

    if (vehicle.year) {
        formattedVehicle.year = vehicle.year;
    }

    if (vehicle.color) {
        formattedVehicle.color = vehicle.color;
    }

    const vehicles = [...userData.vehicles, formattedVehicle] || [formattedVehicle];

    await updateDoc(userDocRef, {
        vehicles,
        plates: arrayUnion(vehicle.plate)
    });
};

const updateVehicleInFirestore = async (vehicle, ownerId) => {
    const userDocRef = doc(db, 'users', ownerId);
    const userDocSnapshot = await getDoc(userDocRef);

    const userData = userDocSnapshot.data();
    const vehicles = userData.vehicles || [];

    const formattedVehicle = {
        country: vehicle.country,
        make: vehicle.make,
        model: vehicle.model,
        primary: true,
        state: vehicle.state,
        tag: vehicle.tag,
        updated_at: fsTimeStampNow()
    };

    if (vehicle.year) {
        formattedVehicle.year = vehicle.year;
    }

    if (vehicle.color) {
        formattedVehicle.color = vehicle.color;
    }

    const updatedVehicles = vehicles.map(v =>
        v.tag === vehicle.tag ? formattedVehicle : v
    );

    await updateDoc(userDocRef, { vehicles: updatedVehicles });
};

function* deleteVehicleFromFirestore(vehicle, ownerId) {
    const userDocRef = doc(db, 'users', ownerId);
    const userDocSnapshot = yield getDoc(userDocRef);

    if (userDocSnapshot.exists()) {
        const userData = userDocSnapshot.data();
        const vehicles = userData.vehicles || [];
        const plates = userData.plates || [];

        const vehiclePlate = createVehiclePlate(vehicle);

        const updatedVehicles = vehicles.filter(v => v.tag !== vehicle.tag);
        const updatedPlates = plates.filter(plate => plate !== vehiclePlate);

        try {
            yield updateDoc(userDocRef, {
                vehicles: updatedVehicles,
                plates: updatedPlates
            });
        } catch (e) {
            console.log(e);
        }
    }
}

const addVehiclePlateToRTDB = async (plate, orgId, userId) => {
    const plateRef = rtdbRef(rtdb, `orgs/${orgId}/members/${userId}/plates/${plate}`);
    await set(plateRef, true);
};

function* removeVehiclePlateFromRTDB(plate, orgId, userId) {
    try {
        const plateRef = rtdbRef(rtdb, `orgs/${orgId}/members/${userId}/plates/${plate}`);
        yield remove(plateRef);
    } catch (e) {
        console.log(e);
    }
}

const updateVehiclePlateInRTDB = async (oldPlate, newPlate, orgId, userId) => {
    await removeVehiclePlateFromRTDB(oldPlate, orgId, userId);
    await addVehiclePlateToRTDB(newPlate, orgId, userId);
};

const updateVehiclePlateInAccessKeys = async (
    vehicle,
    oldPlate,
    newPlate,
    orgId,
    userId
) => {
    const keysQuery = query(
        accessKeysCollectionRef,
        where('org_id', '==', orgId),
        where('consumer_id', '==', userId)
    );

    const querySnapshot = await getDocs(keysQuery);
    querySnapshot.forEach(async docSnapshot => {
        const accessKeyDocRef = doc(db, 'access_keys', docSnapshot.id);
        await updateDoc(accessKeyDocRef, {
            plates: arrayRemove(oldPlate)
        });

        await updateDoc(accessKeyDocRef, {
            plates: arrayUnion(newPlate)
        });
    });
};

const addVehicleToAccessKeys = async (vehicle, orgId, userId) => {
    const keysQuery = query(
        accessKeysCollectionRef,
        where('org_id', '==', orgId),
        where('consumer_id', '==', userId)
    );

    const querySnapshot = await getDocs(keysQuery);

    querySnapshot.forEach(async docSnapshot => {
        const accessKeyDocRef = doc(db, 'access_keys', docSnapshot.id);
        const formattedVehicle = {
            country: vehicle.country,
            make: vehicle.make,
            model: vehicle.model,
            primary: true,
            state: vehicle.state,
            tag: vehicle.tag,
            updated_at: fsTimeStampNow()
        };

        if (vehicle.year) {
            formattedVehicle.year = vehicle.year;
        }

        if (vehicle.color) {
            formattedVehicle.color = vehicle.color;
        }

        const existingVehicles = docSnapshot.data().vehicles || [];
        await updateDoc(accessKeyDocRef, {
            vehicles: [...existingVehicles, formattedVehicle],
            plates: arrayUnion(vehicle.plate)
        });
    });
};

const updateVehicleInAccessKeys = async (vehicle, orgId, userId) => {
    const keysQuery = query(
        accessKeysCollectionRef,
        where('org_id', '==', orgId),
        where('consumer_id', '==', userId)
    );

    const querySnapshot = await getDocs(keysQuery);
    querySnapshot.forEach(async docSnapshot => {
        const accessKeyDocRef = doc(db, 'access_keys', docSnapshot.id);

        const accessKeySnapshot = await getDoc(accessKeyDocRef);
        const accessKeyData = accessKeySnapshot.data();
        const vehicles = accessKeyData.vehicles || [];

        const formattedVehicle = {
            country: vehicle.country,
            make: vehicle.make,
            model: vehicle.model,
            primary: true,
            state: vehicle.state,
            tag: vehicle.tag,
            updated_at: fsTimeStampNow()
        };

        if (vehicle.year) {
            formattedVehicle.year = vehicle.year;
        }

        if (vehicle.color) {
            formattedVehicle.color = vehicle.color;
        }

        const updatedVehicles = vehicles.map(v =>
            v.tag === vehicle.tag ? formattedVehicle : v
        );

        await updateDoc(accessKeyDocRef, {
            vehicles: updatedVehicles
        });
    });
};

const removeVehicleFromAccessKeys = async (vehicle, orgId, userId) => {
    const keysQuery = query(
        accessKeysCollectionRef,
        where('org_id', '==', orgId),
        where('consumer_id', '==', userId)
    );

    const querySnapshot = await getDocs(keysQuery);

    querySnapshot.forEach(async docSnapshot => {
        const accessKeyDocRef = doc(db, 'access_keys', docSnapshot.id);
        const accessKeySnapshot = await getDoc(accessKeyDocRef);

        if (accessKeySnapshot.exists()) {
            const accessKeyData = accessKeySnapshot.data();
            const vehicles = accessKeyData.vehicles || [];
            const plates = accessKeyData.plates || [];

            const vehiclePlate = createVehiclePlate(vehicle);

            const updatedVehicles = vehicles.filter(v => v.tag !== vehicle.tag);
            const updatedPlates = plates.filter(plate => plate !== vehiclePlate);

            await updateDoc(accessKeyDocRef, {
                vehicles: updatedVehicles,
                plates: updatedPlates
            });
        }
    });
};

function* handleAddVehicle(cleanedVehicle, plateForRtdb, ownerId, orgId) {
    yield call(addVehicleToFirestore, cleanedVehicle, ownerId);
    yield call(addVehiclePlateToRTDB, plateForRtdb, orgId, ownerId);
    yield call(addVehicleToAccessKeys, cleanedVehicle, orgId, ownerId);
}

function* handleDeleteVehicle(vehicle, plateForRtdb, ownerId, orgId) {
    yield call(deleteVehicleFromFirestore, vehicle, ownerId);
    yield call(removeVehiclePlateFromRTDB, plateForRtdb, orgId, ownerId);
    yield call(removeVehicleFromAccessKeys, vehicle, orgId, ownerId);
}

function* addVehicle({ payload }) {
    const { vehiclePayload, orgId, orgVehiclePlates } = payload;

    const { ownerId, ...vehicle } = vehiclePayload;

    vehicle.org_id = orgId;

    const cleanedVehicle = cleanDocument(vehicle);

    cleanedVehicle.plate = createVehiclePlate(cleanedVehicle);
    const plateForRtdb = createVehiclePlateForRealtimeDatabase(cleanedVehicle);

    if (!isPlateUnique(plateForRtdb, orgVehiclePlates)) {
        yield put(
            openSnackbar({
                open: true,
                message: `Vehicle plate "${cleanedVehicle.plate}" already exists in the organization`,
                variant: 'alert',
                alert: { color: 'error' }
            })
        );
        return;
    }

    try {
        yield call(handleAddVehicle, cleanedVehicle, plateForRtdb, ownerId, orgId);

        yield put({ type: ADD_VEHICLE_SUCCESS });
        yield put(
            openSnackbar({
                open: true,
                message: 'Vehicle added successfully',
                variant: 'alert',
                alert: { color: 'success' }
            })
        );
    } catch (error) {
        console.log(error, 'error!!');
        yield put({ type: VEHICLE_OPERATION_FAILURE, error });
        yield put(
            openSnackbar({
                open: true,
                message: 'Failed to add vehicle',
                variant: 'alert',
                alert: { color: 'error' }
            })
        );
    }
}

function* updateVehicle({ payload }) {
    const { originalVehiclePayload, updatedVehiclePayload, orgVehiclePlates, orgId } =
        payload;

    const { ownerId: originalOwnerId, ...originalVehicle } = originalVehiclePayload;
    const { ownerId: updatedOwnerId, ...updatedVehicle } = updatedVehiclePayload;

    const cleanedVehicle = cleanDocument(updatedVehicle);

    originalVehicle.plate = createVehiclePlate(originalVehicle);
    cleanedVehicle.plate = createVehiclePlate(cleanedVehicle);

    const originalPlateForRtdb = createVehiclePlateForRealtimeDatabase(originalVehicle);
    const plateForRtdb = createVehiclePlateForRealtimeDatabase(cleanedVehicle);

    if (
        !isPlateUnique(plateForRtdb, orgVehiclePlates) &&
        originalVehicle.plate !== cleanedVehicle.plate
    ) {
        yield put(
            openSnackbar({
                open: true,
                message: `Vehicle plate "${cleanedVehicle.plate}" already exists in the organization`,
                variant: 'alert',
                alert: { color: 'error' }
            })
        );
        return;
    }

    try {
        if (
            originalOwnerId !== updatedOwnerId ||
            originalVehicle.plate !== cleanedVehicle.plate
        ) {
            // If owner or vehicle plate changed, we delete the vehicle from the current owner and add to the new owner
            yield call(
                handleDeleteVehicle,
                originalVehicle,
                originalPlateForRtdb,
                originalOwnerId,
                orgId
            );
            yield call(
                handleAddVehicle,
                cleanedVehicle,
                plateForRtdb,
                updatedOwnerId,
                orgId
            );
        } else {
            yield call(updateVehicleInFirestore, cleanedVehicle, updatedOwnerId);
            yield call(updateVehicleInAccessKeys, cleanedVehicle, orgId, updatedOwnerId);
        }

        yield put({ type: UPDATE_VEHICLE_SUCCESS });
        yield put(
            openSnackbar({
                open: true,
                message: 'Vehicle updated successfully',
                variant: 'alert',
                alert: { color: 'success' }
            })
        );
    } catch (error) {
        yield put({ type: VEHICLE_OPERATION_FAILURE, error });
        yield put(
            openSnackbar({
                open: true,
                message: 'Failed to update vehicle',
                variant: 'alert',
                alert: { color: 'error' }
            })
        );
    }
}

function* deleteVehicle({ payload }) {
    const { vehiclePayload, orgId } = payload;

    const { ownerId, ...vehicle } = vehiclePayload;

    const plateForRtdb = createVehiclePlateForRealtimeDatabase(vehicle);

    try {
        yield call(handleDeleteVehicle, vehicle, plateForRtdb, ownerId, orgId);

        yield put({ type: DELETE_VEHICLE_SUCCESS });
        yield put(
            openSnackbar({
                open: true,
                message: 'Vehicle deleted successfully',
                variant: 'alert',
                alert: { color: 'success' }
            })
        );
    } catch (error) {
        yield put({ type: VEHICLE_OPERATION_FAILURE, error });
        yield put(
            openSnackbar({
                open: true,
                message: 'Failed to delete vehicle',
                variant: 'alert',
                alert: { color: 'error' }
            })
        );
    }
}

export function* watchAddVehicle() {
    yield takeLatest(ADD_VEHICLE, addVehicle);
}

export function* watchUpdateVehicle() {
    yield takeLatest(UPDATE_VEHICLE, updateVehicle);
}

export function* watchDeleteVehicle() {
    yield takeLatest(DELETE_VEHICLE, deleteVehicle);
}

export default function* rootSaga() {
    yield all([
        fork(watchAddVehicle),
        fork(watchUpdateVehicle),
        fork(watchDeleteVehicle)
    ]);
}
