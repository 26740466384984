import {
    FETCH_DEVICE_TYPES,
    FETCH_DEVICE_TYPES_SUCCESS,
    FETCH_DEVICE_TYPES_FAILURE,
    ADD_DEVICE_TYPE_SUCCESS,
    ADD_DEVICE_TYPE_FAILURE
} from '../actions/types';

const INITIAL_STATE = {
    data: {},
    loading: false,
    error: null
};

const deviceTypesReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case FETCH_DEVICE_TYPES:
            return { ...state, loading: true };
        case FETCH_DEVICE_TYPES_SUCCESS:
            return { ...state, loading: false, data: action.payload };
        case FETCH_DEVICE_TYPES_FAILURE:
            return { ...state, loading: false, error: action.payload };
        case ADD_DEVICE_TYPE_SUCCESS:
            return {
                ...state,
                data: {
                    ...state.data,
                    [action.payload.key]: action.payload.value
                }
            };
        case ADD_DEVICE_TYPE_FAILURE:
            return { ...state, error: action.payload };
        default:
            return state;
    }
};

export default deviceTypesReducer;
