import {
    GET_DEVICE_OPERATIONS,
    GET_DEVICE_OPERATIONS_SUCCESS,
    GET_DEVICE_OPERATIONS_FAILURE,
    GET_PROPERTY_MEMBERS,
    GET_PROPERTY_DEVICES_SUCCESS,
    GET_PROPERTY_DEVICES_FAILURE,
    HANDLE_DEVICE,
    HANDLE_DEVICE_SUCCESS,
    HANDLE_DEVICE_FAILURE,
    RESET_DEVICE_STATE,
    CHECK_DEVICE_EXISTS,
    SET_DEVICE_EXISTS_VALIDATION_RESULT
} from '../actions/types';

const INITIAL_STATE = {
    operations: [],
    loading: false,
    error: null,
    success: null,
    devices: null,
    isDeviceExists: null,
    deviceExistsValidationError: null,
    deviceExistsValidationChecked: false
};

const deviceOperationsReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case GET_DEVICE_OPERATIONS:
            return {
                ...state,
                loading: true,
                error: null
            };
        case GET_DEVICE_OPERATIONS_SUCCESS:
            return {
                ...state,
                operations: action.payload,
                loading: false,
                error: null
            };
        case GET_DEVICE_OPERATIONS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload
            };
        case GET_PROPERTY_MEMBERS:
            return {
                ...state,
                error: null
            };
        case GET_PROPERTY_DEVICES_SUCCESS:
            return {
                ...state,
                devices: action.payload
            };
        case GET_PROPERTY_DEVICES_FAILURE:
            return {
                ...state,
                error: action.payload
            };
        case HANDLE_DEVICE:
            return {
                ...state,
                loading: true,
                error: null,
                success: null
            };
        case HANDLE_DEVICE_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
                error: false
            };
        case HANDLE_DEVICE_FAILURE:
            return {
                ...state,
                loading: false,
                success: false,
                error: true
            };
        case RESET_DEVICE_STATE:
            return {
                ...state,
                loading: false,
                success: null,
                error: null
            };
        case CHECK_DEVICE_EXISTS:
            return {
                ...state,
                isDeviceExists: null,
                deviceExistsValidationError: null
            };
        case SET_DEVICE_EXISTS_VALIDATION_RESULT:
            return {
                ...state,
                isDeviceExists: action.payload.isValid,
                deviceExistsValidationChecked: true,
                deviceExistsValidationError: action.payload.errorMessage
            };
        default:
            return state;
    }
};

export default deviceOperationsReducer;
