import React from 'react';
import { Autocomplete as MaterialAutocomplete, TextField } from '@mui/material';

const Autocomplete = ({
    id,
    multiple,
    options,
    value,
    onChange,
    placeholder = 'Select...',
    label = '',
    variant = 'outlined',
    getOptionLabel = option => option,
    filterSelectedOptions,
    isOptionEqualToValue = (option, value) => option === value,
    sx = {},
    error = false
}) => {
    return (
        <MaterialAutocomplete
            multiple={multiple}
            id={id}
            options={options}
            getOptionLabel={getOptionLabel}
            filterSelectedOptions={filterSelectedOptions}
            value={value}
            isOptionEqualToValue={isOptionEqualToValue}
            disableCloseOnSelect
            onChange={(event, newValue) => {
                onChange(newValue);
            }}
            renderInput={params => (
                <TextField
                    {...params}
                    variant={variant}
                    label={label}
                    placeholder={placeholder}
                    error={!!error}
                    sx={{
                        '& fieldset': {
                            borderColor: error ? '#F04134' : ''
                        }
                    }}
                />
            )}
            sx={{
                '& .MuiAutocomplete-tag': {
                    bgcolor: 'primary.lighter',
                    border: '1px solid',
                    height: 29,
                    borderColor: 'primary.light',
                    '& .MuiSvgIcon-root': {
                        color: 'primary.main',
                        '&:hover': {
                            color: 'primary.dark'
                        },
                        height: 19
                    }
                },
                ...sx
            }}
        />
    );
};

export default Autocomplete;
