import React from 'react';
import { Typography, Stack } from '@mui/material';
import Dot from 'components/@extended/Dot';

const PropertyStatusCell = value => {
    const color = 'success';
    const count = value;

    return (
        <Stack direction="row" spacing={1} alignItems="center">
            <Dot color={color} />
            <Typography>{count}</Typography>
        </Stack>
    );
};

export default PropertyStatusCell;
