////////////////////////////// Alerts //////////////////////////////
export const UPDATE_SEEN_ALERT = 'update_seen_alert';

////////////////////////////// Auth //////////////////////////////
export const CHECK_USER_AUTHENTICATED = 'check_user_authenticated';
export const USER_AUTHENTICATED_SUCCESS = 'user_authenticated_success';
export const USER_AUTHENTICATED_FAILURE = 'user_authenticated_failure';
export const LOGIN_USER_EMAIL_PASSWORD = 'login_user_email_password';
export const LOGIN_USER_FAILURE = 'login_user_failure';
export const LOGOUT_USER = 'logout_user';
export const LOGOUT_USER_SUCCESS = 'logout_user_success';
export const CLEAR_AUTH_ERROR = 'clear_auth_error';
export const RESET_USER_PASSWORD = 'reset_user_password';
export const NON_AUTH_RESET_USER_PASSWORD = 'non_auth_reset_user_password';
export const NON_AUTH_RESET_USER_PASSWORD_SUCCESS =
    'non_auth_reset_user_password_success';
export const NON_AUTH_RESET_USER_PASSWORD_FAILURE =
    'non_auth_reset_user_password_failure';
export const RESET_PASSWORD_REQUEST_STATE = 'reset_password_request_state';
export const EXECUTE_RESET_PASSWORD_FLIGHT = 'execute_reset_password_flight';
export const VERIFY_ACTION_CODE = 'verify_action_code';
export const VALIDATE_INVITE_CODE = 'validate_invite_code';
export const VALIDATE_INVITE_CODE_SUCCESS = 'validate_invite_code_success';
export const VALIDATE_INVITE_CODE_FAILURE = 'validate_invite_code_failure';
export const RESET_VALIDATE_INVITE_CODE = 'validate_invite_code_reset';
export const RESEND_INVITE_CODE = 'resend_invite_code';
export const RESEND_INVITE_CODE_SUCCESS = 'resend_invite_code_success';
export const RESEND_INVITE_CODE_FAILURE = 'resend_invite_code_failure';
export const RESET_RESEND_INVITE_CODE = 'reset_resend_invite_code';
export const ACTION_CODE_VALID = 'action_code_valid';
export const ACTION_CODE_INVALID = 'action_code_invalid';
export const RESET_PASSWORD_SUCCESS = 'reset_password_success';
export const RESET_PASSWORD_FAILURE = 'reset_password_failure';
export const UPDATE_AUTH_USER_PASSWORD = 'update_auth_user_password';
export const UPDATE_AUTH_USER_PASSWORD_SUCCESS = 'update_auth_user_password_success';
export const UPDATE_AUTH_USER_PASSWORD_FAILURE = 'update_auth_user_password_failure';
export const UPDATE_AUTH_USER_PASSWORD_RESET = 'update_auth_user_password_reset';
export const UPDATE_AUTH_USER_EMAIL = 'update_auth_user_email';
export const UPDATE_AUTH_USER_EMAIL_SUCCESS = 'update_auth_user_email_success';
export const UPDATE_AUTH_USER_EMAIL_FAILURE = 'update_auth_user_email_failure';
export const UPDATE_AUTH_USER_EMAIL_RESET = 'update_auth_user_email_reset';
export const MANAGER_IS_CHANGING_PASSWORD = 'manager_is_changing_password';
export const RESET_INITIAL_MANAGER_PASSWORD = 'reset_initial_manager_password';
export const CREATE_AUTH_USER = 'create_auth_user';
export const CREATE_AUTH_USER_SUCCESS = 'create_auth_user_success';
export const CREATE_AUTH_USER_FAILURE = 'create_auth_user_failure';

////////////////////////////// Calls ///////////////////////////////
export const GET_CALLS_COLLECTION = 'get_calls_collection';
export const STORE_CALLS_COLLECTION = 'store_calls_collection';
export const FIELD_PANEL_CALL = 'field_panel_call';
export const END_PANEL_CALL = 'end_panel_call';
export const FIELD_PANEL_CALL_SUCCESS = 'field_panel_call_success';
export const END_PANEL_CALL_SUCCESS = 'end_panel_call_success';
export const FIELD_PANEL_CALL_FAILURE = 'field_panel_call_failure';
export const END_PANEL_CALL_FAILURE = 'end_panel_call_failure';

////////////////////////////// Members /////////////////////////////
export const ADD_NEW_MEMBER = 'add_new_member';
export const ADD_NEW_MEMBER_SUCCESS = 'add_new_member_success';
export const ADD_NEW_MEMBER_FAILURE = 'add_new_member_failure';
export const RESET_ADD_NEW_MEMBER = 'reset_add_new_member';
export const GET_EXISTING_MEMBER = 'get_existing_member';
export const SET_EXISTING_MEMBER = 'set_existing_member';
export const REMOVE_MEMBERS = 'remove_members';
export const UPDATE_MEMBER = 'update_member';
export const TRANSFER_MEMBER = 'transfer_member';
export const TRANSFER_MEMBER_SUCCESS = 'transfer_member_success';
export const TRANSFER_MEMBER_FAILURE = 'transfer_member_failure';
export const REMOVE_REGISTERS = 'remove_registers';
export const UPDATE_REGISTER = 'update_register';
export const UPDATE_REGISTER_SUCCESS = 'updated_register_success';
export const UPDATE_REGISTER_FAILURE = 'updated_register_failure';
export const SEND_INVITES = 'send_invites';
export const REMOVE_PENDING = 'remove_pending';
export const SUSPEND_PROPERTY_GUEST = 'suspend_property_guest';
export const CREATE_MEMBER_USER = 'create_member_user';
export const CREATE_MEMBER_USER_SUCCESS = 'create_member_user_success';
export const CREATE_MEMBER_USER_FAILURE = 'create_member_user_failure';
export const REMOVE_MEMBER_USER_SUCCESS = 'remove_member_user_success';
export const REMOVE_MEMBER_USER_FAILURE = 'remove_member_user_failure';
export const ADD_EDIT_MEMBER_DEVICE = 'add_edit_member_device';
export const ADD_EDIT_MEMBER_DEVICE_SUCCESS = 'add_edit_member_device_success';
export const ADD_EDIT_MEMBER_DEVICE_FAILURE = 'add_edit_member_device_failure';
export const REMOVE_MEMBER_DEVICE = 'remove_member_device';
export const REMOVE_MEMBER_DEVICE_SUCCESS = 'remove_member_device_success';
export const REMOVE_MEMBER_DEVICE_FAILURE = 'remove_member_device_failure';

/////////////// Member (Manager, Resident, Vendor) //////////
export const ADD_MEMBER = 'add_member';
export const ADD_MEMBER_SUCCESS = 'add_member_success';
export const ADD_MEMBER_FAILURE = 'add_member_failure';
export const RESET_MEMBER_CREATION_STATE = 'reset_member_creation_state';
export const IMPORT_MEMBERS = 'import_members';
export const IMPORT_MEMBERS_SUCCESS = 'import_members_success';
export const IMPORT_MEMBERS_FAILURE = 'import_members_failure';
export const RESET_MEMBER_IMPORT_STATE = 'reset_member_import_state';
export const GET_IMPORTED_MEMBERS_FOR_ORG = 'get_imported_members';
export const GET_IMPORTED_MEMBERS_FOR_ORG_SUCCESS = 'get_imported_members_success';
export const GET_IMPORTED_MEMBERS_FOR_ORG_FAILURE = 'get_imported_members_failure';
export const SET_INVITE_PENDING_MEMBERS = 'set_invite_pending_members';
export const RESET_INVITE_PENDING_MEMBERS = 'reset_invite_pending_members';
export const ADD_INVITE_PENDING_MEMBERS = 'add_invite_pending_members';
export const ADD_INVITE_PENDING_MEMBERS_SUCCESS = 'add_invite_pending_members_success';
export const ADD_INVITE_PENDING_MEMBERS_FAILURE = 'add_invite_pending_members_failure';
export const GET_INVITED_MEMBERS_FOR_ORG = 'get_invited_members';
export const GET_INVITED_MEMBERS_FOR_ORG_SUCCESS = 'get_invited_members_success';
export const GET_INVITED_MEMBERS_FOR_ORG_FAILURE = 'get_invited_members_failure';
export const GET_COMMUNITY_MEMBERS_FOR_ORG = 'get_community_members';
export const GET_COMMUNITY_MEMBERS_FOR_ORG_SUCCESS = 'get_community_members_success';
export const GET_COMMUNITY_MEMBERS_FOR_ORG_FAILURE = 'get_community_members_failure';
export const GET_ACCESS_KEYS_DOCUMENT = 'get_access_keys_document';
export const GET_ACCESS_KEYS_DOCUMENT_SUCCESS = 'get_access_keys_document_success';
export const GET_ACCESS_KEYS_DOCUMENT_FAILURE = 'get_access_keys_document_failure';
export const ADD_ACCESS_KEYS_DOCUMENT = 'add_access_keys_document';
export const ADD_ACCESS_KEYS_DOCUMENT_SUCCESS = 'add_access_keys_document_success';
export const ADD_ACCESS_KEYS_DOCUMENT_FAILURE = 'add_access_keys_document_failure';
export const GET_POSTS = 'get_posts';
export const GET_POSTS_SUCCESS = 'get_posts_success';
export const GET_POSTS_FAILURE = 'get_posts_failure';
export const ADD_POST = 'add_post';
export const ADD_POST_SUCCESS = 'add_post_success';
export const ADD_POST_FAILURE = 'add_post_failure';
export const DELETE_POST = 'delete_post';
export const DELETE_POST_SUCCESS = 'delete_post_success';
export const DELETE_POST_FAILURE = 'delete_post_failure';
export const UPDATE_ACCESS_LOG = 'update_access_log';
export const VALIDATE_INVITE = 'validate_invite';
export const VALIDATE_INVITE_SUCCESS = 'validate_invite_success';
export const VALIDATE_INVITE_FAILURE = 'validate_invite_failure';
export const SET_INVITE_PROCESSING_STATUS = 'set_invite_processing_status';
export const DECLINE_INVITE = 'decline_invite';
export const DECLINE_INVITE_SUCCESS = 'decline_invite_success';
export const DECLINE_INVITE_FAILURE = 'decline_invite_failure';
export const ACCEPT_INVITE = 'accept_invite';
export const ACCEPT_INVITE_SUCCESS = 'accept_invite_success';
export const ACCEPT_INVITE_FAILURE = 'accept_invite_failure';
export const ADD_MEMBERS_FROM_LIST = 'add_members_from_list';
export const CREATE_ACCESS_API_CALL = 'create_access_api_call';
export const CREATE_ACCESS_API_SUCCESS = 'create_access_api_success';
export const CREATE_ACCESS_API_MATCHED_ACCOUNT = 'create_access_api_matched_account';
export const CREATE_ACCESS_API_VERIFY_NUMBER = 'create_access_api_verify_number';
export const CREATE_ACCESS_API_NEW_VERIFICATION = 'create_access_api_new_verification';
export const CREATE_ACCESS_API_NEW_VERIFICATION_ADDED =
    'create_access_api_new_verification_added';
export const CREATE_ACCESS_API_VERIFICATION_SUCCESS =
    'create_access_api_verification_success';
export const CREATE_ACCESS_API_ERROR_MESSAGE = 'create_access_api_error_message';
export const CREATE_ACCESS_API_START_OVER = 'create_access_api_start_over';
export const RESEND_MANAGER_INVITE = 'resend_manager_invite';
export const RESEND_MANAGER_INVITE_SUCCESS = 'resend_manager_invite_success';
export const RESEND_MANAGER_INVITE_FAILURE = 'resend_manager_invite_failure';
export const VALIDATE_SHORT_CODE = 'validate_short_code';
export const SET_SHORT_CODE_VALIDATION_RESULT = 'set_short_code_validation_result';

////////////////////////////// Menu ///////////////////////////////
export const SET_ACTIVE_ITEM = 'set_active_menu';
export const SET_ACTIVE_ID = 'set_active_id';
export const SET_ACTIVE_COMPONENT = 'set_active_component';
export const SET_OPEN_DRAWER = 'set_open_drawer';
export const SET_OPEN_COMPONENT_DRAWER = 'set_open_component_drawer';
export const SET_MENU_ERROR = 'set_menu_error';

////////////////////////////// Panels //////////////////////////////
export const GET_PANEL_DATA = 'get_panel_data';
export const SET_PANEL_DATA = 'set_panel_data';
export const GET_DEVICE_TYPES = 'get_device_types';
export const GET_DEVICE_TYPES_SUCCESS = 'get_device_types_success';
export const GET_DEVICE_TYPES_FAILURE = 'get_device_types_failure';
export const GET_ORG_DEVICES_RTDB = 'get_org_devices_rtdb';
export const GET_ORG_DEVICES_RTDB_SUCCESS = 'get_org_devices_rtdb_success';
export const GET_ORG_DEVICES_RTDB_FAILURE = 'get_org_devices_rtdb_failure';
export const UPDATE_ORG_DEVICE_DETAILS = 'update_org_device_details';
export const UPDATE_ORG_DEVICE_DETAILS_SUCCESS = 'update_org_device_details_success';
export const UPDATE_ORG_DEVICE_DETAILS_FAILURE = 'update_org_device_details_failure';
export const UPDATE_ORG_DEVICE_DETAILS_RESET = 'update_org_device_details_reset';
export const GET_ORG_PANELS = 'get_org_panels';
export const GET_ORG_PANELS_SUCCESS = 'get_org_panels_success';
export const GET_ORG_PANELS_FAILURE = 'get_org_panels_failure';
export const STORE_PANEL_DEVICES_DATA = 'STORE_PANEL_DEVICES_DATA';
export const ADD_PANEL = 'add_panel';
export const ADD_PANEL_SUCCESS = 'add_panel_success';
export const ADD_PANEL_FAILURE = 'add_panel_failure';
export const UPDATE_PANEL = 'update_panel';
export const UPDATE_PANEL_SUCCESS = 'update_panel_success';
export const UPDATE_PANEL_FAILURE = 'update_panel_failure';
export const REMOVE_PANEL = 'remove_panel';
export const REMOVE_PANEL_SUCCESS = 'remove_panel_success';
export const REMOVE_PANEL_FAILURE = 'remove_panel_failure';

//////////////////////////// Ca Panels //////////////////////////////
export const GET_RTDB_CA_PANELS = 'get_rtdb_ca_panels';
export const GET_RTDB_CA_PANELS_SUCCESS = 'get_rtdb_ca_panels_success';
export const GET_RTDB_CA_PANELS_FAILURE = 'get_rtdb_ca_panels_failure';
export const GET_CA_PANELS = 'get_ca_panels';
export const GET_CA_PANELS_SUCCESS = 'get_ca_panels_success';
export const GET_CA_PANELS_FAILURE = 'get_ca_panels_failure';
export const ASSIGN_CA_PANEL_TO_ORG = 'assign_ca_panel_to_org';
export const ASSIGN_CA_PANEL_TO_ORG_SUCCESS = 'assign_ca_panel_to_org_success';
export const ASSIGN_CA_PANEL_TO_ORG_FAILURE = 'assign_ca_panel_to_org_failure';
export const HANDLE_CA_PANEL = 'handle_ca_panel';
export const HANDLE_CA_PANEL_SUCCESS = 'handle_ca_panel_success';
export const HANDLE_CA_PANEL_FAILURE = 'handle_ca_panel_failure';
export const HANDLE_CA_READER = 'handle_ca_reader';
export const HANDLE_CA_READER_SUCCESS = 'handle_ca_reader_success';
export const HANDLE_CA_READER_FAILURE = 'handle_ca_reader_failure';
export const HANDLE_CA_RELAY = 'handle_ca_relay';
export const HANDLE_CA_RELAY_SUCCESS = 'handle_ca_relay_success';
export const HANDLE_CA_RELAY_FAILURE = 'handle_ca_relay_failure';
export const HANDLE_CA_ACCESS_GROUP = 'handle_ca_access_group';
export const HANDLE_CA_ACCESS_GROUP_SUCCESS = 'handle_ca_access_group_success';
export const HANDLE_CA_ACCESS_GROUP_FAILURE = 'handle_ca_access_group_failure';
export const HANDLE_CA_TIME_SCHEDULE = 'handle_ca_time_schedule';
export const HANDLE_CA_TIME_SCHEDULE_SUCCESS = 'handle_ca_time_schedule_success';
export const HANDLE_CA_TIME_SCHEDULE_FAILURE = 'handle_ca_time_schedule_failure';
export const UNASSIGN_CA_PANEL_FROM_ORG = 'unassign_ca_panel_from_org';
export const UNASSIGN_CA_PANEL_FROM_ORG_SUCCESS = 'unassign_ca_panel_from_org_success';
export const UNASSIGN_CA_PANEL_FROM_ORG_FAILURE = 'unassign_ca_panel_from_org_failure';
export const RESET_CA_STATE = 'reset_ca_state';

////////////////////////////// Org //////////////////////////////
export const GET_ORGS_COLLECTION = 'get_orgs_collection';
export const GET_ORGS_COLLECTION_SUCCESS = 'get_orgs_collection_success';
export const GET_ORGS_COLLECTION_FAILURE = 'get_orgs_collection_failure';
export const GET_ACTIVE_ORG_COLLECTION = 'get_active_org_collection';
export const GET_ACTIVE_ORG_COLLECTION_SUCCESS = 'get_active_org_collection_success';
export const GET_ACTIVE_ORG_COLLECTION_FAILURE = 'get_active_org_collection_failure';
export const GET_USER_ORG = 'get_user_org';
export const SET_USER_ORG = 'set_user_org';
export const SET_PRIMARY_ORG = 'set_primary_org';
export const CREATE_ORG = 'create_org';
export const CREATE_ORG_SUCCESS = 'create_org_success';
export const CREATE_ORG_FAILURE = 'create_org_failure';
export const RESET_ORG_CREATION_STATE = 'reset_org_creation_state';
export const REMOVE_USER_FROM_ORG = 'remove_user_from_org';
export const ADD_INVITE_TO_ORG = 'add_invite_to_org';
export const ADD_INVITE_TO_ORG_SUCCESS = 'add_invite_to_org_success';
export const ADD_INVITE_TO_ORG_FAILURE = 'add_invite_to_org_failure';
export const RESET_INVITE_ADDING_STATE = 'reset_invite_adding_state';
export const GET_DEFAULT_ROLES_COLLECTION = 'get_default_roles_collection';
export const GET_DEFAULT_ROLES_COLLECTION_SUCCESS =
    'get_default_roles_collection_success';
export const GET_DEFAULT_ROLES_COLLECTION_FAILURE =
    'get_default_roles_collection_failure';
export const SET_SELECTED_ORG = 'set_selected_org';
export const UPDATE_ORG = 'update_org';
export const UPDATE_ORG_SUCCESS = 'update_org_success';
export const UPDATE_ORG_FAILURE = 'update_org_failure';
export const RESET_ORG_UPDATE_STATE = 'reset_org_update_state';
export const UPDATE_ORG_IMAGE = 'update_org_image';
export const UPDATE_ORG_IMAGE_SUCCESS = 'update_org_image_success';
export const UPDATE_ORG_IMAGE_FAILURE = 'update_org_image_failure';

export const ADD_TAG_MASTER_CAMERA = 'add_tag_master_camera';
export const ADD_TAG_MASTER_CAMERA_SUCCESS = 'add_tag_master_camera_success';
export const ADD_TAG_MASTER_CAMERA_FAILURE = 'add_tag_master_camera_failure';
export const UPDATE_TAG_MASTER_CAMERA = 'update_tag_master_camera';
export const UPDATE_TAG_MASTER_CAMERA_SUCCESS = 'update_tag_master_camera_success';
export const UPDATE_TAG_MASTER_CAMERA_FAILURE = 'update_tag_master_camera_failure';
export const DELETE_TAG_MASTER_CAMERA = 'delete_tag_master_camera';
export const DELETE_TAG_MASTER_CAMERA_SUCCESS = 'delete_tag_master_camera_success';
export const DELETE_TAG_MASTER_CAMERA_FAILURE = 'delete_tag_master_camera_failure';
export const RESET_TAG_MASTER_SUCCESS_STATE = 'reset_tag_master_success_state';

////////////////////////////// Properties //////////////////////////////

export const GET_ORG_PROPERITES_COLLECTION = 'get_org_properties_collection';
export const GET_ORG_PROPERITES_COLLECTION_SUCCESS =
    'get_org_properties_collection_success';
export const GET_ORG_PROPERITES_COLLECTION_FAILURE =
    'get_org_properties_collection_failure';
export const ADD_ORG_PROPERITY = 'add_org_property';
export const ADD_ORG_PROPERITY_SUCCESS = 'add_org_property_success';
export const ADD_ORG_PROPERITY_FAILURE = 'add_org_property_failure';
export const IMPORT_ORG_PROPERITES = 'import_org_properties';
export const IMPORT_ORG_PROPERITES_SUCCESS = 'import_org_properties_success';
export const IMPORT_ORG_PROPERITES_FAILURE = 'import_org_properties_failure';
export const RESET_IMPORT_ORG_PROPERITES = 'reset_import_org_properties';
export const GET_PROPERTY_BY_ID = 'get_property_by_id';
export const GET_PROPERTY_BY_ID_SUCCESS = 'get_property_by_id_success';
export const GET_PROPERTY_BY_ID_FAILURE = 'get_property_by_id_failure';
export const DELETE_PROPERITY = 'delete_property';
export const DELETE_PROPERITY_SUCCESS = 'delete_property_success';
export const DELETE_PROPERITY_FAILURE = 'delete_property_failure';
export const SELECT_PROPERTY = 'select_property';
export const GET_ORG_PROPERITES_BY_SHORT_CODE = 'get_org_properties_by_short_code';
export const GET_ORG_PROPERITES_BY_SHORT_CODE_SUCCESS =
    'get_org_properties_by_short_code_success';
export const GET_ORG_PROPERITES_BY_SHORT_CODE_FAILURE =
    'get_org_properties_by_short_code_failure';
export const UPDATE_ORG_PROPERTY = 'update_org_property';
export const UPDATE_ORG_PROPERTY_SUCCESS = 'update_org_property_success';
export const UPDATE_ORG_PROPERTY_FAILURE = 'update_org_property_failure';
export const CLEAR_PROPERTY_DATA = 'clear_property_data';
export const CLEAR_PROPERTY_DATA_SUCCESS = 'clear_property_data_success';
export const CLEAR_PROPERTY_DATA_FAILURE = 'clear_property_data_failure';

////////////////////////////// Reservations /////////////////////////////////

export const ADD_RESERVATION = 'add_reservation';
export const ADD_RESERVATION_SUCCESS = 'add_reservation_success';
export const ADD_RESERVATION_FAILURE = 'add_reservation_failure';
export const RESET_ADD_RESERVATION_STATE = 'reset_add_reservation_state';

export const GET_RESERVATIONS = 'get_reservations';
export const GET_RESERVATIONS_SUCCESS = 'get_reservations_success';
export const GET_RESERVATIONS_FAILURE = 'get_reservations_failure';

export const WATCH_RESERVATIONS = 'watch_reservations';

export const CREATE_TENANT_ACCESS = 'create_tenant_access';
export const CREATE_TENANT_ACCESS_SUCCESS = 'create_tenant_access_success';
export const CREATE_TENANT_ACCESS_FAILURE = 'create_tenant_access_failure';

export const VALIDATE_RESERVATION = 'validate_reservation';
export const VALIDATE_RESERVATION_SUCCESS = 'validate_reservation_success';
export const VALIDATE_RESERVATION_FAILURE = 'validate_reservation_failure';

export const CANCEL_RESERVATION = 'cancel_reservation';
export const CANCEL_RESERVATION_SUCCESS = 'cancel_reservation_success';
export const CANCEL_RESERVATION_FAILURE = 'cancel_reservation_failure';

export const REINSTATE_RESERVATION = 'reinstate_reservation';
export const REINSTATE_RESERVATION_SUCCESS = 'reinstate_reservation_success';
export const REINSTATE_RESERVATION_FAILURE = 'reinstate_reservation_failure';

export const UPDATE_RESERVATION = 'update_reservation';
export const UPDATE_RESERVATION_SUCCESS = 'update_reservation_success';
export const UPDATE_RESERVATION_FAILURE = 'update_reservation_failure';

export const IMPORT_RESERVATIONS_CSV = 'import_reservations_csv';
export const IMPORT_RESERVATIONS_CSV_FAILURE = 'import_reservations_csv_failure';
export const IMPORT_RESERVATIONS_CSV_SUCCESS = 'import_reservations_csv_success';
export const RESET_IMPORT_RESERVATIONS_STATE = 'reset_import_reservations_state';

export const RESET_RESERVATION_ACTIONS_STATE = 'reset_reservation_actions_state';
export const STORE_GUARD_RESERVATION_ID = 'store_guard_reservation_id';

////////////////////////////// Snackbar //////////////////////////
export const SET_OPEN_SNACKBAR = 'set_open_snackbar';
export const SET_CLOSE_SNACKBAR = 'set_close_snackbar';
export const SET_HANDLER_INCREASE = 'set_handler_increase';
export const SET_HANDLER_DENSE = 'set_handler_dense';
export const SET_HANDLER_ICON_VARIANTS = 'set_handler_icon_variants';

////////////////////////////// User //////////////////////////////
export const GET_USER_COLLECTION = 'get_user_collection';
export const SET_USER_COLLECTION = 'set_user_collection';
export const SET_ACTIVE_USER_ORG = 'set_active_user_org';
export const SET_ACTIVE_USER_ORG_SUCCESS = 'set_active_user_org_success';
export const SET_ACTIVE_USER_ORG_FAILURE = 'set_active_user_org_failure';
export const SET_ACTIVE_USER_PROPERTY = 'set_active_user_property';
export const SET_ACTIVE_USER_PROPERTY_SUCCESS = 'set_active_user_property_success';
export const SET_ACTIVE_USER_PROPERTY_FAILURE = 'set_active_user_property_failure';
export const UPDATE_USER_AVATAR = 'update_user_avatar';
export const UPDATE_USER_AVATAR_SUCCESS = 'update_user_avatar_success';
export const UPDATE_USER_AVATAR_FAILURE = 'update_user_avatar_failure';
export const UPDATE_USER_PROFILE_INFO = 'update_user_profile_info';
export const UPDATE_USER_PROFILE_INFO_SUCCESS = 'update_user_profile_info_success';
export const UPDATE_USER_PROFILE_INFO_FAILURE = 'update_user_profile_info_failure';
export const UPDATE_USER_PROFILE_INFO_RESET = 'update_user_profile_info_reset';
export const SET_ACTIVE_USER_ORG_PERMISSIONS = 'get_active_org_permissions';
export const UPDATE_FAMILY_MEMBER = 'update_family_member';
export const UPDATE_FAMILY_MEMBER_SUCCESS = 'update_family_member_success';
export const UPDATE_FAMILY_MEMBER_FAILURE = 'update_family_member_failure';
export const RESET_UPDATE_FAMILY_MEMBER = 'reset_update_family_member';

////////////////////////////// Resi Guests /////////////////////////////////

export const ADD_NEW_GUEST = 'add_new_guest';
export const GET_GUESTS = 'get_guests';
export const SET_GUEST_INVITES = 'set_guest_invites';
export const SET_GUEST_ACCESS_KEYS = 'set_guest_access_keys';
export const SET_ONE_TIME_ACCESS_CODES = 'set_one_time_access_codes';
export const ADD_NEW_GUEST_SUCCESS = 'add_new_guest_success';
export const ADD_NEW_GUEST_FAILURE = 'add_new_guest_failure';
export const RESET_ADD_NEW_GUEST = 'reset_add_new_guest';
export const REMOVE_GUEST = 'remove_guest';
export const REMOVE_GUEST_SUCCESS = 'remove_guest_sucess';
export const REMOVE_GUEST_FAILURE = 'remove_guest_failure';
export const RESET_REMOVE_GUEST = 'reset_remove_guest';

///////////////////////// Intercom ///////////////////////////

export const GET_INTERCOM_COLLECTION = 'get_intercom_collection';
export const STORE_INTERCOM_COLLECTION = 'store_intercom_collection';
export const CREATE_PANEL_INTERCOM = 'create_panel_intercom';
export const CREATE_PANEL_INTERCOM_SUCCESS = 'create_panel_intercom_success';
export const CREATE_PANEL_INTERCOM_FAILURE = 'create_panel_intercom_failure';
export const END_PANEL_INTERCOM = 'end_panel_intercom';
export const END_PANEL_INTERCOM_SUCCESS = 'end_panel_intercom_success';
export const END_PANEL_INTERCOM_FAILURE = 'end_panel_intercom_failure';

///////////////////////// Virtual Guard ///////////////////////////
export const GET_PROPERTY_MEMBERS = 'get_property_members';
export const GET_PROPERTY_MEMBERS_SUCCESS = 'get_property_members_success';
export const GET_PROPERTY_MEMBERS_FAILURE = 'get_property_members_failure';
export const GET_INVITED_PROPERTY_MEMBERS_SUCCESS =
    'get_invited_property_members_success';
export const GET_INVITED_PROPERTY_MEMBERS_FAILURE =
    'get_invited_property_members_failure';
export const GET_MEMBER_KEYS = 'get_member_details';
export const GET_MEMBER_KEYS_SUCCESS = 'get_member_keys_success';
export const GET_MEMBER_KEYS_FAILURE = 'get_member_keys_failure';
export const RESET_PROPERTY_MEMBERS = 'reset_property_members';
export const RESET_MEMBER_KEYS = 'reset_member_keys';
export const ALLOW_GATE_ACCESS = 'allow_gate_access';
export const ALLOW_GATE_ACCESS_SUCCESS = 'allow_gate_access_success';
export const ALLOW_GATE_ACCESS_FAILURE = 'allow_gate_access_failure';
export const DISALLOW_GATE_ACCESS = 'disallow_gate_access';
export const DISALLOW_GATE_ACCESS_SUCCESS = 'disallow_gate_access_success';
export const DISALLOW_GATE_ACCESS_FAILURE = 'disallow_gate_access_failure';

////////////////////////////// Registration /////////////////////////////////
export const ADD_NEW_REGISTRATION = 'add_new_registration';
export const ADD_NEW_OCCUPANT_REGISTRATION = 'add_new_occupant_registration';
export const ADD_NEW_REGISTRATION_SUCCESS = 'add_new_registration_success';
export const ADD_NEW_REGISTRATION_FAILURE = 'add_new_registration_failure';
export const RESET_ADD_NEW_REGISTRATION = 'reset_add_new_registration';
export const RESET_REMOVE_REGISTRATION = 'reset_remove_registration';
export const REMOVE_REGISTRATION = 'remove_registration';
export const REMOVE_REGISTRATION_SUCCESS = 'remove_registration_success';
export const REMOVE_REGISTRATION_FAILURE = 'remove_registration_failure';
export const GET_REGISTRATIONS_BY_PROPERTY_ID = 'get_registration_by_property_id';
export const GET_REGISTRATIONS_BY_PROPERTY_ID_SUCCESS =
    'get_registration_by_property_id_success';
export const GET_REGISTRATIONS_BY_PROPERTY_ID_FAILURE =
    'get_registration_by_property_id_failure';
export const EDIT_REGISTRATION = 'edit_registration';
export const EDIT_REGISTRATION_SUCCESS = 'edit_registration_success';
export const EDIT_REGISTRATION_FAILURE = 'edit_registration_failure';
export const RESET_EDIT_REGISTRATION = 'reset_edit_registration';

////////////////////////////// Otc //////////////////////////////
export const GET_ORG_OTC_CODES = 'get_org_otc';
export const GET_ORG_OTC_SUCCESS = 'get_org_otc_success';
export const ADD_OTC = 'add_otc';
export const ADD_OTC_SUCCESS = 'add_otc_success';
export const ADD_OTC_FAILURE = 'add_otc_failure';
export const REVOKE_OTC = 'revoke_otc';
export const REVOKE_OTC_SUCCESS = 'revoke_otc_success';
export const REVOKE_OTC_FAILURE = 'revoke_otc_failure';
export const RESET_OTC_STATE = 'reset_otc_state';

////////////////////////////// LiveKit //////////////////////////////

export const GENERATE_LIVEKIT_TOKEN = 'generate_livekit_token';
export const GENERATE_LIVEKIT_TOKEN_SUCCESS = 'generate_livekit_token_success';
export const GENERATE_LIVEKIT_TOKEN_FAILURE = 'generate_livekit_token_failure';
export const RESET_LIVEKIT_TOKEN = 'reset_livekit_token';

//////////////////////////////// Lpr ////////////////////////////////
export const GET_LPR_COLLECTION = 'get_lpr_collection';
export const GET_LPR_COLLECTION_SUCCESS = 'get_lpr_collection_success';
export const GET_LPR_COLLECTION_FAILURE = 'get_lpr_collection_failure';
export const SET_LPR_QUERY = 'set_lpr_query';
export const STORE_LPR_IDS = 'store_lpr_ids';

////////////////////////// Device Operations /////////////////////////
export const GET_DEVICE_OPERATIONS = 'get_device_operations';
export const GET_DEVICE_OPERATIONS_SUCCESS = 'get_device_operations_success';
export const GET_DEVICE_OPERATIONS_FAILURE = 'get_device_operations_failure';
export const HANDLE_DEVICE = 'handle_device';
export const HANDLE_DEVICE_SUCCESS = 'handle_device_success';
export const HANDLE_DEVICE_FAILURE = 'handle_device_failure';
export const RESET_DEVICE_STATE = 'reset_device_state';
export const GET_PROPERTY_DEVICES_SUCCESS = 'get_property_devices_success';
export const GET_PROPERTY_DEVICES_FAILURE = 'get_property_devices_failure';
export const CHECK_DEVICE_EXISTS = 'check_device_exists';
export const SET_DEVICE_EXISTS_VALIDATION_RESULT = 'set_device_exists_validation_result';

/////////////////////////////// Vehicle //////////////////////////////
export const ADD_VEHICLE = 'add_vehicle';
export const UPDATE_VEHICLE = 'update_vehicle';
export const DELETE_VEHICLE = 'delete_vehicle';
export const ADD_VEHICLE_SUCCESS = 'add_vehicle_success';
export const UPDATE_VEHICLE_SUCCESS = 'update_vehicle_success';
export const DELETE_VEHICLE_SUCCESS = 'delete_vehicle_success';
export const VEHICLE_OPERATION_FAILURE = 'vehicle_operation_failure';
export const RESET_VEHICLE_OPERATION_STATE = 'reset_vehicle_operation_state';

//////////////////////////////// Table ///////////////////////////////
export const SET_PAGE_INDEX = 'set_page_index';
export const SET_ROW_COUNT = 'set_row_count';

//////////////////////////////// CSV Template ///////////////////////////////
export const DOWNLOAD_CSV_TEMPLATE = 'download_csv_template';
export const DOWNLOAD_CSV_TEMPLATE_SUCCESS = 'download_csv_template_success';
export const DOWNLOAD_CSV_TEMPLATE_FAILURE = 'download_csv_template_failure';

//////////////////////////////// Reports ///////////////////////////////
export const GET_REPORT_PROPERTIES = 'get_report_properties';
export const GET_REPORT_PROPERTIES_SUCCESS = 'get_report_properties_success';
export const GET_REPORT_PROPERTIES_FAILURE = 'get_report_properties_failure';

//////////////////////////////// Device Types ///////////////////////////////
export const FETCH_DEVICE_TYPES = 'fetch_device_types';
export const FETCH_DEVICE_TYPES_SUCCESS = 'fetch_device_types_success';
export const FETCH_DEVICE_TYPES_FAILURE = 'fetch_device_types_failure';
export const ADD_DEVICE_TYPE = 'add_device_type';
export const ADD_DEVICE_TYPE_SUCCESS = 'add_device_type_success';
export const ADD_DEVICE_TYPE_FAILURE = 'add_device_type_failure';
