import {
    GET_CA_PANELS_SUCCESS,
    GET_CA_PANELS_FAILURE,
    GET_RTDB_CA_PANELS_SUCCESS,
    GET_RTDB_CA_PANELS_FAILURE,
    RESET_CA_STATE,
    HANDLE_CA_PANEL,
    HANDLE_CA_PANEL_SUCCESS,
    HANDLE_CA_PANEL_FAILURE,
    HANDLE_CA_READER,
    HANDLE_CA_READER_SUCCESS,
    HANDLE_CA_READER_FAILURE,
    HANDLE_CA_RELAY,
    HANDLE_CA_RELAY_SUCCESS,
    HANDLE_CA_RELAY_FAILURE,
    HANDLE_CA_ACCESS_GROUP,
    HANDLE_CA_ACCESS_GROUP_SUCCESS,
    HANDLE_CA_ACCESS_GROUP_FAILURE,
    HANDLE_CA_TIME_SCHEDULE,
    HANDLE_CA_TIME_SCHEDULE_SUCCESS,
    HANDLE_CA_TIME_SCHEDULE_FAILURE
} from '../actions/types';

const INITIAL_STATE = {
    caPanels: [],
    rtdbCaPanels: {},
    error: null,
    panelError: null,
    caPanel: null,
    loading: false,
    success: false
};

const caPanelsReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case GET_CA_PANELS_SUCCESS:
            return {
                ...state,
                caPanels: action.payload,
                error: null
            };
        case GET_CA_PANELS_FAILURE:
            return {
                ...state,
                caPanels: null,
                error: action.payload
            };
        case HANDLE_CA_PANEL:
        case HANDLE_CA_READER:
        case HANDLE_CA_RELAY:
        case HANDLE_CA_ACCESS_GROUP:
        case HANDLE_CA_TIME_SCHEDULE:
            return {
                ...state,
                loading: true,
                error: null,
                success: null
            };
        case HANDLE_CA_PANEL_SUCCESS:
        case HANDLE_CA_READER_SUCCESS:
        case HANDLE_CA_RELAY_SUCCESS:
        case HANDLE_CA_ACCESS_GROUP_SUCCESS:
        case HANDLE_CA_TIME_SCHEDULE_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
                error: false
            };
        case HANDLE_CA_PANEL_FAILURE:
        case HANDLE_CA_READER_FAILURE:
        case HANDLE_CA_RELAY_FAILURE:
        case HANDLE_CA_ACCESS_GROUP_FAILURE:
        case HANDLE_CA_TIME_SCHEDULE_FAILURE:
            return {
                ...state,
                loading: false,
                success: false,
                error: true
            };
        case RESET_CA_STATE:
            return {
                ...state,
                loading: null,
                success: null,
                error: null
            };
        case GET_RTDB_CA_PANELS_SUCCESS:
            return {
                ...state,
                rtdbCaPanels: action.payload,
                error: null
            };
        case GET_RTDB_CA_PANELS_FAILURE:
            return {
                ...state,
                rtdbCaPanels: null,
                error: action.payload
            };
        default:
            return state;
    }
};

export default caPanelsReducer;
