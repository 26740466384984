import store from '../../store';
import { showModal } from '../../store/slices/modalSlice';

export const openModal = (content, props, modalContainerProps = {}) => {
    store.dispatch(showModal({ content, props, modalContainerProps }));
};

export const openCompactModal = (content, props) => {
    store.dispatch(
        showModal({ content, props, modalContainerProps: { maxWidth: 'xs' } })
    );
};
