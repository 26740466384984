import {
    GET_CA_PANELS_SUCCESS,
    GET_CA_PANELS_FAILURE,
    GET_RTDB_CA_PANELS_SUCCESS,
    GET_RTDB_CA_PANELS_FAILURE,
    ASSIGN_CA_PANEL_TO_ORG,
    ASSIGN_CA_PANEL_TO_ORG_SUCCESS,
    ASSIGN_CA_PANEL_TO_ORG_FAILURE,
    HANDLE_CA_PANEL,
    HANDLE_CA_PANEL_SUCCESS,
    HANDLE_CA_PANEL_FAILURE,
    HANDLE_CA_READER,
    HANDLE_CA_READER_SUCCESS,
    HANDLE_CA_READER_FAILURE,
    HANDLE_CA_RELAY,
    HANDLE_CA_RELAY_SUCCESS,
    HANDLE_CA_RELAY_FAILURE,
    HANDLE_CA_ACCESS_GROUP,
    HANDLE_CA_ACCESS_GROUP_SUCCESS,
    HANDLE_CA_ACCESS_GROUP_FAILURE,
    HANDLE_CA_TIME_SCHEDULE,
    HANDLE_CA_TIME_SCHEDULE_SUCCESS,
    HANDLE_CA_TIME_SCHEDULE_FAILURE,
    UNASSIGN_CA_PANEL_FROM_ORG,
    UNASSIGN_CA_PANEL_FROM_ORG_SUCCESS,
    UNASSIGN_CA_PANEL_FROM_ORG_FAILURE,
    RESET_CA_STATE
} from './types';

export const getCaPanelsSuccess = caPanels => ({
    type: GET_CA_PANELS_SUCCESS,
    payload: caPanels
});

export const getCaPanelsFailure = error => ({
    type: GET_CA_PANELS_FAILURE,
    payload: error
});

export const getRtdbCaPanelsSuccess = caPanels => ({
    type: GET_RTDB_CA_PANELS_SUCCESS,
    payload: caPanels
});

export const getRtdbCaPanelsFailure = error => ({
    type: GET_RTDB_CA_PANELS_FAILURE,
    payload: error
});

export const assignCaPanelToOrg = (orgId, caPanelId) => ({
    type: ASSIGN_CA_PANEL_TO_ORG,
    payload: { orgId, caPanelId }
});

export const assignCaPanelToOrgSuccess = (caPanelId, orgId) => ({
    type: ASSIGN_CA_PANEL_TO_ORG_SUCCESS,
    payload: { caPanelId, orgId }
});

export const assignCaPanelToOrgFailure = error => ({
    type: ASSIGN_CA_PANEL_TO_ORG_FAILURE,
    payload: error
});

export const resetCaState = () => ({
    type: RESET_CA_STATE
});

export const handleCaPanel = panel => ({
    type: HANDLE_CA_PANEL,
    payload: panel
});

export const handleCaPanelSuccess = () => ({
    type: HANDLE_CA_PANEL_SUCCESS
});

export const handleCaPanelFailure = error => ({
    type: HANDLE_CA_PANEL_FAILURE,
    payload: error
});

export const handleCaReader = reader => ({
    type: HANDLE_CA_READER,
    payload: reader
});

export const handleCaReaderSuccess = () => ({
    type: HANDLE_CA_READER_SUCCESS
});

export const handleCaReaderFailure = error => ({
    type: HANDLE_CA_READER_FAILURE,
    payload: error
});

export const handleCaRelay = reader => ({
    type: HANDLE_CA_RELAY,
    payload: reader
});

export const handleCaRelaySuccess = () => ({
    type: HANDLE_CA_RELAY_SUCCESS
});

export const handleCaRelayFailure = error => ({
    type: HANDLE_CA_RELAY_FAILURE,
    payload: error
});

export const handleCaAccessGroup = accessGroup => ({
    type: HANDLE_CA_ACCESS_GROUP,
    payload: accessGroup
});

export const handleCaAccessGroupsSuccess = () => ({
    type: HANDLE_CA_ACCESS_GROUP_SUCCESS
});

export const handleCaAccessGroupsFailure = error => ({
    type: HANDLE_CA_ACCESS_GROUP_FAILURE,
    payload: error
});

export const handleCaTimeSchedule = timeSchedule => ({
    type: HANDLE_CA_TIME_SCHEDULE,
    payload: timeSchedule
});

export const handleCaTimeScheduleSuccess = () => ({
    type: HANDLE_CA_TIME_SCHEDULE_SUCCESS
});

export const handleCaTimeScheduleFailure = error => ({
    type: HANDLE_CA_TIME_SCHEDULE_FAILURE,
    payload: error
});

export const unassignCaPanelFromOrg = (orgId, caPanelId) => ({
    type: UNASSIGN_CA_PANEL_FROM_ORG,
    payload: { caPanelId, orgId }
});

export const unassignCaPanelFromOrgSuccess = caPanelId => ({
    type: UNASSIGN_CA_PANEL_FROM_ORG_SUCCESS,
    payload: { caPanelId }
});

export const unassignCaPanelFromOrgFailure = error => ({
    type: UNASSIGN_CA_PANEL_FROM_ORG_FAILURE,
    payload: error
});
