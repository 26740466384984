import React from 'react';
import {
    Select as MaterialSelect,
    MenuItem,
    FormControl,
    FormHelperText
} from '@mui/material';

const Select = ({
    label,
    name,
    value,
    options,
    onChange,
    onBlur,
    touched,
    error,
    required = false,
    helperText = '',
    menuItemsProps = {},
    ...rest
}) => {
    return (
        <FormControl fullWidth error={touched && Boolean(error)}>
            <MaterialSelect
                name={name}
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                required={required}
                {...rest}
            >
                {options.map(option => (
                    <MenuItem key={option.key} value={option.key} {...menuItemsProps}>
                        {option.value}
                    </MenuItem>
                ))}
            </MaterialSelect>
            {helperText && <FormHelperText>{helperText}</FormHelperText>}
        </FormControl>
    );
};

export default Select;
