import React from 'react';
import { connect } from 'react-redux';
import TagMasterCamerasTable from './TagMasterCamerasTable';
import { Button, Grid } from '@mui/material';
import { MainCard } from '../../../components';
import { openCompactModal, openModal } from '../../../utils/modal/modal.helper';
import CreateUpdateTagMasterCameraModal from './CreateUpdateTagMasterCameraModal';
import { MODAL_ACTION_TYPE } from '../../../utils/constants/modal';
import {
    addTagMasterCamera,
    updateTagMasterCamera,
    deleteTagMasterCamera
} from '../../../store/actions/Org';
import DeleteModal from '../../../components/DeleteModal';

const TabTagMasterCameras = ({
    orgId,
    locations,
    tagMasterCams,
    addTagMasterCamera,
    updateTagMasterCamera,
    deleteTagMasterCamera
}) => {
    const handleOpenCameraModal = (camera, type = MODAL_ACTION_TYPE.ADD) => {
        openModal(CreateUpdateTagMasterCameraModal, {
            camera,
            locations,
            modalType: type,
            onSubmit: camera => {
                camera.id
                    ? updateTagMasterCamera(orgId, camera)
                    : addTagMasterCamera(orgId, camera);
            },
            onDelete: camera => handleDelete(camera)
        });
    };

    const handleDelete = camera => {
        openCompactModal(DeleteModal, {
            title: `${camera.ip_address}`,
            body: 'Delete Camera',
            onDelete: () => {
                deleteTagMasterCamera(orgId, camera.id);
            }
        });
    };

    return (
        <Grid item xs={12}>
            <MainCard
                title="LPR Cameras"
                content={false}
                secondary={
                    <Button onClick={() => handleOpenCameraModal({})}>
                        Assign Camera
                    </Button>
                }
            >
                <TagMasterCamerasTable
                    cameras={tagMasterCams}
                    locations={locations}
                    onCameraClick={camera =>
                        handleOpenCameraModal(camera, MODAL_ACTION_TYPE.EDIT)
                    }
                />
            </MainCard>
        </Grid>
    );
};

const mapStateToProps = ({ org, user }) => {
    const { selectedOrg } = org;
    const { activeOrg: userOrg } = user;

    const activeOrg = userOrg || selectedOrg;

    const { locations, hardware } = activeOrg;

    const tagMasterCams = hardware
        ? hardware.filter(device => device.type === 'tag-master-lpr')
        : [];

    return {
        orgId: activeOrg.org_id,
        locations,
        tagMasterCams
    };
};

export default connect(mapStateToProps, {
    addTagMasterCamera,
    updateTagMasterCamera,
    deleteTagMasterCamera
})(TabTagMasterCameras);
