import {
    all,
    fork,
    takeLatest,
    put,
    call,
    race,
    cancelled,
    take
} from 'redux-saga/effects';
import {
    HANDLE_CA_PANEL,
    ASSIGN_CA_PANEL_TO_ORG,
    GET_CA_PANELS,
    GET_RTDB_CA_PANELS,
    LOGOUT_USER,
    UNASSIGN_CA_PANEL_FROM_ORG,
    HANDLE_CA_READER,
    HANDLE_CA_ACCESS_GROUP,
    HANDLE_CA_TIME_SCHEDULE,
    HANDLE_CA_RELAY
} from '../actions/types';
import { ref, get, set, child, remove } from 'firebase/database';
import { db, func, rtdb } from 'config/firebase';
import {
    assignCaPanelToOrgFailure,
    assignCaPanelToOrgSuccess,
    getCaPanelsFailure,
    getCaPanelsSuccess,
    getRtdbCaPanelsFailure,
    getRtdbCaPanelsSuccess,
    handleCaAccessGroupsFailure,
    handleCaAccessGroupsSuccess,
    handleCaPanelFailure,
    handleCaPanelSuccess,
    handleCaReaderFailure,
    handleCaReaderSuccess,
    handleCaRelayFailure,
    handleCaRelaySuccess,
    handleCaTimeScheduleFailure,
    handleCaTimeScheduleSuccess,
    unassignCaPanelFromOrgFailure,
    unassignCaPanelFromOrgSuccess
} from '../actions/CaPanels';
import { eventChannel } from 'redux-saga';
import {
    collection,
    doc,
    onSnapshot,
    query,
    updateDoc,
    getDoc
} from 'firebase/firestore';
import { openSnackbar } from '../actions/Snackbar';
import { httpsCallable } from 'firebase/functions';
import { getOperationString } from 'utils/Helpers';

export const getRtdbOrgCaPanelsRef = orgId => ref(rtdb, `orgs/${orgId}/ca_panels`);

const caPanelCollectionRef = collection(db, 'ca_panels');

const addEditCaPanel = httpsCallable(func, 'addEditCaPanel');
const addEditCaReader = httpsCallable(func, 'addEditCaReader');
const addEditCaRelay = httpsCallable(func, 'addEditCaRelay');
const addEditCaAccessGroup = httpsCallable(func, 'addEditCaAccessGroup');
const addEditCaTimeSchedule = httpsCallable(func, 'addEditCaTimeSchedule');

function* listenToFirebaseChannel(query, successAction, additionalDataProcessor = null) {
    const channel = eventChannel(emit => {
        const unsubscribe = onSnapshot(query, querySnapshot => {
            const data = [];
            querySnapshot.forEach(doc => {
                data.push({ id: doc.id, ...doc.data() });
            });

            if (additionalDataProcessor) {
                emit(additionalDataProcessor(data));
            } else {
                emit(data);
            }
        });

        return unsubscribe;
    });

    try {
        while (true) {
            const { userSignOut, data } = yield race({
                userSignOut: take(LOGOUT_USER),
                data: take(channel)
            });

            if (userSignOut) {
                channel.close();
                break;
            } else {
                yield put(successAction(data));
            }
        }
    } finally {
        if (yield cancelled()) {
            channel.close();
        }
    }
}

export function* listenToCaPanels() {
    try {
        const caPanelsCollectionRef = collection(db, 'ca_panels');
        const queryRef = query(caPanelsCollectionRef);

        yield call(listenToFirebaseChannel, queryRef, getCaPanelsSuccess);
    } catch (error) {
        yield put(getCaPanelsFailure(error.message));
    }
}

export function* getRtdbCaPanels(payload) {
    const { active_org_id } = payload;

    try {
        const caPanelsRef = getRtdbOrgCaPanelsRef(active_org_id);
        const caPanelsSnapshot = yield call(get, caPanelsRef);

        if (caPanelsSnapshot.exists()) {
            const caPanelsData = caPanelsSnapshot.val();
            yield put(getRtdbCaPanelsSuccess(caPanelsData));
        } else {
            yield put(getRtdbCaPanelsFailure('No Ca Panels data found.'));
        }
    } catch (error) {
        yield put(getRtdbCaPanelsFailure(error.message));
    }
}

export function* assignCaPanelToOrg({ payload }) {
    const { orgId, caPanelId } = payload;

    try {
        const caPanelDocRef = doc(db, 'ca_panels', caPanelId);
        const caPanelSnapshot = yield call(getDoc, caPanelDocRef);

        if (caPanelSnapshot.exists()) {
            const caPanelData = caPanelSnapshot.data();

            yield call(updateDoc, caPanelDocRef, { org_id: orgId });

            const orgCaPanelsRef = getRtdbOrgCaPanelsRef(orgId);
            yield call(set, child(orgCaPanelsRef, caPanelId), {
                ...caPanelData,
                org_id: orgId
            });

            yield put(assignCaPanelToOrgSuccess(caPanelId, orgId));

            yield fork(getRtdbCaPanels, { active_org_id: orgId });

            yield put(
                openSnackbar({
                    open: true,
                    message: `CA Panel assigned successfully`,
                    variant: 'alert',
                    alert: {
                        color: 'success'
                    }
                })
            );
        } else {
            throw new Error('CA Panel not found');
        }
    } catch (error) {
        yield put(assignCaPanelToOrgFailure(error.message));

        yield put(
            openSnackbar({
                open: true,
                message: `Failed to assign CA Panel: ${error.message}`,
                variant: 'alert',
                alert: {
                    color: 'error'
                }
            })
        );
    }
}

export function* unassignCaPanelFromOrg({ payload }) {
    const { orgId, caPanelId } = payload;

    try {
        const caPanelDocRef = doc(db, 'ca_panels', caPanelId);
        const caPanelSnapshot = yield call(getDoc, caPanelDocRef);

        if (caPanelSnapshot.exists()) {
            yield call(updateDoc, caPanelDocRef, { org_id: null });

            const orgCaPanelsRef = getRtdbOrgCaPanelsRef(orgId);
            yield call(remove, child(orgCaPanelsRef, caPanelId));

            yield put(unassignCaPanelFromOrgSuccess(caPanelId));

            yield fork(getRtdbCaPanels, { active_org_id: orgId });

            yield put(
                openSnackbar({
                    open: true,
                    message: `CA Panel unassigned successfully`,
                    variant: 'alert',
                    alert: {
                        color: 'success'
                    }
                })
            );
        } else {
            throw new Error('CA Panel not found');
        }
    } catch (error) {
        yield put(unassignCaPanelFromOrgFailure(error.message));

        yield put(
            openSnackbar({
                open: true,
                message: `Failed to unassign CA Panel: ${error.message}`,
                variant: 'alert',
                alert: {
                    color: 'error'
                }
            })
        );
    }
}

export function* handlingPanel({ payload }) {
    console.log('payload:', payload);
    const result = yield call(addEditCaPanel, payload);
    console.log('result', result);

    const operationStatus = getOperationString(payload.operation);

    if (result?.data?.success) {
        yield put(handleCaPanelSuccess());
        yield put(
            openSnackbar({
                open: true,
                message: `Success: Panel has been ${operationStatus}!`,
                variant: 'alert',
                alert: {
                    color: 'success'
                }
            })
        );
    } else {
        yield put(handleCaPanelFailure());
        yield put(
            openSnackbar({
                open: true,
                message: `Failed: Panel was not ${operationStatus} successfully.`,
                variant: 'alert',
                alert: {
                    color: 'error'
                }
            })
        );
    }
}

export function* handlingReader({ payload }) {
    console.log('payload:', payload);
    const result = yield call(addEditCaReader, payload);
    console.log('result', result);
    const operationStatus = getOperationString(payload.operation);

    if (result?.data?.success) {
        yield put(handleCaReaderSuccess());
        yield put(
            openSnackbar({
                open: true,
                message: `Success: Reader has been ${operationStatus}!`,
                variant: 'alert',
                alert: {
                    color: 'success'
                }
            })
        );
    } else {
        yield put(handleCaReaderFailure());
        yield put(
            openSnackbar({
                open: true,
                message: `Failed: Reader was not ${operationStatus} successfully.`,
                variant: 'alert',
                alert: {
                    color: 'error'
                }
            })
        );
    }
}

export function* handlingRelay({ payload }) {
    console.log('payload:', payload);
    const result = yield call(addEditCaRelay, payload);
    console.log('result', result);
    const operationStatus = getOperationString(payload.operation);

    if (result?.data?.success) {
        yield put(handleCaRelaySuccess());
        yield put(
            openSnackbar({
                open: true,
                message: `Success: Relay has been ${operationStatus}!`,
                variant: 'alert',
                alert: {
                    color: 'success'
                }
            })
        );
    } else {
        yield put(handleCaRelayFailure());
        yield put(
            openSnackbar({
                open: true,
                message: `Failed: Relay was not ${operationStatus} successfully.`,
                variant: 'alert',
                alert: {
                    color: 'error'
                }
            })
        );
    }
}

export function* handlingAccessGroup({ payload }) {
    console.log('payload:', payload);
    const result = yield call(addEditCaAccessGroup, payload);
    console.log('result', result);
    const operationStatus = getOperationString(payload.operation);

    if (result?.data?.success) {
        yield put(handleCaAccessGroupsSuccess());
        yield put(
            openSnackbar({
                open: true,
                message: `Success: Access Group has been ${operationStatus}!`,
                variant: 'alert',
                alert: {
                    color: 'success'
                }
            })
        );
    } else {
        yield put(handleCaAccessGroupsFailure());
        yield put(
            openSnackbar({
                open: true,
                message: `Failed: Access Group was not ${operationStatus} successfully.`,
                variant: 'alert',
                alert: {
                    color: 'error'
                }
            })
        );
    }
}

export function* handlingTimeSchedule({ payload }) {
    console.log('payload:', payload);
    const result = yield call(addEditCaTimeSchedule, payload);
    console.log('result', result);
    const operationStatus = getOperationString(payload.operation);

    if (result?.data?.success) {
        yield put(handleCaTimeScheduleSuccess());
        yield put(
            openSnackbar({
                open: true,
                message: `Success: Time Schedule has been ${operationStatus}!`,
                variant: 'alert',
                alert: {
                    color: 'success'
                }
            })
        );
    } else {
        yield put(handleCaTimeScheduleFailure());
        yield put(
            openSnackbar({
                open: true,
                message: `Failed: Time Schedule was not ${operationStatus} successfully.`,
                variant: 'alert',
                alert: {
                    color: 'error'
                }
            })
        );
    }
}

export function* watchGetCaPanels() {
    yield takeLatest(GET_CA_PANELS, listenToCaPanels);
}

export function* watchGetRtdbCaPanels() {
    yield takeLatest(GET_RTDB_CA_PANELS, getRtdbCaPanels);
}

export function* watchAssignCaPanelToOrg() {
    yield takeLatest(ASSIGN_CA_PANEL_TO_ORG, assignCaPanelToOrg);
}

export function* watchUnassignCaPanelFromOrg() {
    yield takeLatest(UNASSIGN_CA_PANEL_FROM_ORG, unassignCaPanelFromOrg);
}

export function* handlePanel() {
    yield takeLatest(HANDLE_CA_PANEL, handlingPanel);
}

export function* handleReader() {
    yield takeLatest(HANDLE_CA_READER, handlingReader);
}

export function* handleRelay() {
    yield takeLatest(HANDLE_CA_RELAY, handlingRelay);
}

export function* handleAccessGroup() {
    yield takeLatest(HANDLE_CA_ACCESS_GROUP, handlingAccessGroup);
}

export function* handleTimeSchedule() {
    yield takeLatest(HANDLE_CA_TIME_SCHEDULE, handlingTimeSchedule);
}

export default function* caPanelsSaga() {
    yield all([
        fork(watchGetCaPanels),
        fork(watchGetRtdbCaPanels),
        fork(watchAssignCaPanelToOrg),
        fork(watchUnassignCaPanelFromOrg),
        fork(handlePanel),
        fork(handleReader),
        fork(handleRelay),
        fork(handleAccessGroup),
        fork(handleTimeSchedule)
    ]);
}
