import React from 'react';
import PropTypes from 'prop-types';

// material-ui
import { useTheme } from '@mui/material/styles';
import { ThemeMode } from 'config';

import logoIconDark from 'assets/images/sage-light.svg';
import logoIcon from 'assets/images/sage-light.svg';

// ==============================|| LOGO SVG ||============================== //

const LogoMain = () => {
    const theme = useTheme();
    return (
        <img
            src={theme.palette.mode === ThemeMode.DARK ? logoIconDark : logoIcon}
            alt="SAGE"
            width="114"
        />
    );
};

LogoMain.propTypes = {
    reverse: PropTypes.bool
};

export default LogoMain;
