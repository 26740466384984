import { all, fork, takeLatest, call } from 'redux-saga/effects';

import { UPDATE_SEEN_ALERT } from '../actions/types';

import { doc, updateDoc } from 'firebase/firestore';
import { db } from 'config/firebase';
import { fsTimeStampNow } from 'config/firebase';

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////// Update Seen Alert //////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

const updatingSeenAlertRequest = async ({ userId, orgId, type }) => {
    const orgDocRef = doc(db, 'orgs', orgId);

    await updateDoc(orgDocRef, {
        [`alerts.${userId}.${type}`]: fsTimeStampNow()
    });
    return { updated: true };
};

function* updatingSeenAlert({ payload }) {
    const { userId, orgId, type } = payload;
    const { updated, error } = yield call(updatingSeenAlertRequest, {
        userId,
        orgId,
        type
    });
    if (updated) {
        // console.log('updated!!');
    } else {
        // console.log('Update Alert Error', error);
    }
}

///////////// Action Creators For Root Saga //////////////////

export function* updateSeenAlert() {
    yield takeLatest(UPDATE_SEEN_ALERT, updatingSeenAlert);
}

export default function* rootSaga() {
    yield all([fork(updateSeenAlert)]);
}
