import {
    GET_EXISTING_MEMBER,
    SET_EXISTING_MEMBER,
    ADD_NEW_MEMBER,
    ADD_NEW_MEMBER_SUCCESS,
    ADD_NEW_MEMBER_FAILURE,
    RESET_ADD_NEW_MEMBER,
    REMOVE_MEMBERS,
    UPDATE_MEMBER,
    UPDATE_REGISTER,
    UPDATE_REGISTER_SUCCESS,
    UPDATE_REGISTER_FAILURE,
    REMOVE_REGISTERS,
    SEND_INVITES,
    REMOVE_PENDING,
    SUSPEND_PROPERTY_GUEST,
    CREATE_MEMBER_USER,
    CREATE_MEMBER_USER_SUCCESS,
    CREATE_MEMBER_USER_FAILURE,
    ADD_EDIT_MEMBER_DEVICE,
    ADD_EDIT_MEMBER_DEVICE_SUCCESS,
    ADD_EDIT_MEMBER_DEVICE_FAILURE,
    REMOVE_MEMBER_DEVICE,
    REMOVE_MEMBER_DEVICE_SUCCESS,
    REMOVE_MEMBER_DEVICE_FAILURE,
    REMOVE_MEMBER_USER_SUCCESS,
    TRANSFER_MEMBER,
    TRANSFER_MEMBER_SUCCESS,
    TRANSFER_MEMBER_FAILURE
} from './types';

export const checkingExistingMember = member => {
    return {
        type: GET_EXISTING_MEMBER,
        payload: member
    };
};

export const setExistingMember = existing => {
    return {
        type: SET_EXISTING_MEMBER,
        payload: existing
    };
};

export const addingNewMember = member => {
    return {
        type: ADD_NEW_MEMBER,
        payload: member
    };
};

export const addingNewMemberSuccess = () => {
    return {
        type: ADD_NEW_MEMBER_SUCCESS
    };
};

export const addingNewMemberFailure = error => {
    return {
        type: ADD_NEW_MEMBER_FAILURE,
        payload: error
    };
};

export const resetNewMember = type => {
    return {
        type: RESET_ADD_NEW_MEMBER,
        payload: type
    };
};

export const removingMember = members => {
    return {
        type: REMOVE_MEMBERS,
        payload: members
    };
};

export const removeMemberSuccess = () => {
    return {
        type: REMOVE_MEMBER_USER_SUCCESS
    };
};

export const updatingMember = member => {
    return {
        type: UPDATE_MEMBER,
        payload: member
    };
};

export const transferMember = payload => {
    return {
        type: TRANSFER_MEMBER,
        payload
    };
};

export const transferMemberSuccess = () => {
    return {
        type: TRANSFER_MEMBER_SUCCESS
    };
};

export const transferMemberFailure = error => {
    return {
        type: TRANSFER_MEMBER_FAILURE,
        payload: error
    };
};

export const removingRegisters = registers => {
    return {
        type: REMOVE_REGISTERS,
        payload: registers
    };
};

export const updatingRegister = register => {
    return {
        type: UPDATE_REGISTER,
        payload: register
    };
};

export const updatingRegisterSuccess = () => {
    return {
        type: UPDATE_REGISTER_SUCCESS
    };
};

export const updatingRegisterFailure = error => {
    return {
        type: UPDATE_REGISTER_FAILURE,
        payload: error
    };
};

export const sendingInvites = invites => {
    return {
        type: SEND_INVITES,
        payload: invites
    };
};

export const removingPending = pending => {
    return {
        type: REMOVE_PENDING,
        payload: pending
    };
};

export const suspendingPropertyGuest = guest => {
    return {
        type: SUSPEND_PROPERTY_GUEST,
        payload: guest
    };
};

export const createMemberUser = payload => {
    return {
        type: CREATE_MEMBER_USER,
        payload: payload
    };
};

export const createMemberUserSuccess = () => {
    return {
        type: CREATE_MEMBER_USER_SUCCESS
    };
};

export const createMemberUserFailure = error => {
    return {
        type: CREATE_MEMBER_USER_FAILURE,
        payload: error
    };
};

export const addEditingMemberDevice = device => {
    return {
        type: ADD_EDIT_MEMBER_DEVICE,
        payload: device
    };
};

export const addEditingMemberDeviceSuccess = () => {
    return {
        type: ADD_EDIT_MEMBER_DEVICE_SUCCESS
    };
};

export const addEditingMemberDeviceFailure = error => {
    return {
        type: ADD_EDIT_MEMBER_DEVICE_FAILURE,
        payload: error
    };
};

export const removingMemberDevice = device => {
    return {
        type: REMOVE_MEMBER_DEVICE,
        payload: device
    };
};

export const removingMemberDeviceSuccess = () => {
    return {
        type: REMOVE_MEMBER_DEVICE_SUCCESS
    };
};

export const removingMemberDeviceFailure = error => {
    return {
        type: REMOVE_MEMBER_DEVICE_FAILURE,
        payload: error
    };
};

// export const addingNewMemberSuccess = () => {
//     return {
//         type: ADD_NEW_MEMBER_SUCCESS
//     };
// };

// export const addingNewMemberFailure = error => {
//     return {
//         type: ADD_NEW_MEMBER_FAILURE,
//         payload: error
//     };
// };
