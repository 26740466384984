import React, { lazy } from 'react';

// project import
import MainLayout from 'layout/MainLayout';
import Loadable from 'components/Loadable';
import AdminGuard from 'utils/route-guard/AdminGuard';
import { Navigate } from 'react-router';
import TabTagMasterCameras from '../sections/view-edit-org/tab-tag-master-cameras/TabTagMasterCameras';

// pages maintenance pages
const MaintenanceError = Loadable(lazy(() => import('pages/maintenance/404')));

// render - main-pages
const Organizations = Loadable(lazy(() => import('pages/main-pages/Organizations')));
const CreateOrg = Loadable(lazy(() => import('pages/main-pages/CreateOrg')));
const ViewEditOrg = Loadable(lazy(() => import('pages/main-pages/ViewEditOrg')));
const TabInfo = Loadable(lazy(() => import('sections/view-edit-org/TabInfo')));
const TabUsers = Loadable(lazy(() => import('sections/view-edit-org/TabUsers')));
const TabPanels = Loadable(lazy(() => import('sections/view-edit-org/TabPanels')));
const TabModules = Loadable(lazy(() => import('sections/view-edit-org/TabModules')));
const TabStations = Loadable(lazy(() => import('sections/view-edit-org/TabStations')));
const AnalsysTabPanel = Loadable(
    lazy(() => import('components/Analysis/AnalsysTabPanel'))
);
const TabDeviceFacilityCodes = Loadable(
    lazy(() => import('sections/view-edit-org/TabDeviceFacilityCodes'))
);
const Property = Loadable(lazy(() => import('pages/main-pages/Property')));
const TabProperties = Loadable(
    lazy(() => import('sections/view-edit-org/TabProperties'))
);

const DeviceOperations = Loadable(
    lazy(() => import('pages/main-pages/DeviceOperations'))
);
const Reports = Loadable(lazy(() => import('pages/main-pages/Reports')));

// ==============================|| ADMIN ROUTING ||============================== //

// end CloudVideo

const AdminRoutes = {
    path: '/',
    children: [
        {
            path: '*',
            element: <MaintenanceError />
        },
        {
            path: '/',
            element: (
                <AdminGuard>
                    <MainLayout />
                </AdminGuard>
            ),
            children: [
                {
                    path: 'orgs',
                    element: <Organizations />
                },
                {
                    path: 'orgs/:orgId',
                    element: <ViewEditOrg />,
                    children: [
                        {
                            path: 'org-info',
                            element: <TabInfo />
                        },
                        {
                            path: 'org-users',
                            element: <TabUsers />
                        },
                        {
                            path: 'org-modules',
                            element: <TabModules />
                        },
                        {
                            path: 'org-stations',
                            element: <TabStations />
                        },
                        {
                            path: 'org-panels',
                            element: <TabPanels />
                        },
                        {
                            path: 'tag-master-cameras',
                            element: <TabTagMasterCameras />
                        },
                        {
                            path: 'org-properties',
                            element: <TabProperties />
                        },
                        {
                            path: 'access-control',
                            element: <AnalsysTabPanel />
                        },
                        {
                            path: 'device-facility-code',
                            element: <TabDeviceFacilityCodes />
                        },
                        {
                            path: 'org-properties/:id',
                            element: <Property />
                        },
                        { path: '*', element: <Navigate to="org-info" replace /> }
                    ]
                },
                {
                    path: 'create-org',
                    element: <CreateOrg />
                },
                {
                    path: 'device-operations',
                    element: <DeviceOperations />
                },
                {
                    path: 'reports',
                    element: <Reports />
                }
            ]
        }
    ]
};

export default AdminRoutes;
