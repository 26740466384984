import {
    GET_ORG_PROPERITES_COLLECTION,
    GET_ORG_PROPERITES_COLLECTION_SUCCESS,
    GET_ORG_PROPERITES_COLLECTION_FAILURE,
    GET_ORG_PROPERITES_BY_SHORT_CODE,
    GET_ORG_PROPERITES_BY_SHORT_CODE_SUCCESS,
    GET_ORG_PROPERITES_BY_SHORT_CODE_FAILURE,
    ADD_ORG_PROPERITY,
    IMPORT_ORG_PROPERITES,
    IMPORT_ORG_PROPERITES_SUCCESS,
    IMPORT_ORG_PROPERITES_FAILURE,
    RESET_IMPORT_ORG_PROPERITES,
    GET_PROPERTY_BY_ID,
    GET_PROPERTY_BY_ID_FAILURE,
    GET_PROPERTY_BY_ID_SUCCESS,
    DELETE_PROPERITY,
    DELETE_PROPERITY_SUCCESS,
    DELETE_PROPERITY_FAILURE,
    SELECT_PROPERTY,
    SET_ACTIVE_USER_PROPERTY,
    SET_ACTIVE_USER_PROPERTY_SUCCESS,
    SET_ACTIVE_USER_PROPERTY_FAILURE,
    UPDATE_ORG_PROPERTY,
    UPDATE_ORG_PROPERTY_SUCCESS,
    UPDATE_ORG_PROPERTY_FAILURE,
    CLEAR_PROPERTY_DATA,
    CLEAR_PROPERTY_DATA_SUCCESS,
    CLEAR_PROPERTY_DATA_FAILURE
} from './types';

export const selectProperty = property => {
    return {
        type: SELECT_PROPERTY,
        payload: property
    };
};

export const getOrgPropertiesCollection = org => {
    return {
        type: GET_ORG_PROPERITES_COLLECTION,
        payload: org
    };
};

export const getOrgPropertiesCollectionFailure = error => {
    return {
        type: GET_ORG_PROPERITES_COLLECTION_FAILURE,
        payload: error
    };
};

export const getOrgPropertiesCollectionSuccess = data => {
    return {
        type: GET_ORG_PROPERITES_COLLECTION_SUCCESS,
        payload: data
    };
};

export const getOrgPropertiesByShortCode = shortCode => {
    return {
        type: GET_ORG_PROPERITES_BY_SHORT_CODE,
        payload: shortCode
    };
};

export const getOrgPropertiesByShortCodeFailure = error => {
    return {
        type: GET_ORG_PROPERITES_BY_SHORT_CODE_FAILURE,
        payload: error
    };
};

export const getOrgPropertiesByShortCodeSuccess = data => {
    return {
        type: GET_ORG_PROPERITES_BY_SHORT_CODE_SUCCESS,
        payload: data
    };
};

export const resetImportOrgProperties = () => {
    return {
        type: RESET_IMPORT_ORG_PROPERITES
    };
};

export const importOrgProperties = payload => {
    return {
        type: IMPORT_ORG_PROPERITES,
        payload: payload
    };
};

export const importOrgPropertiesFailure = error => {
    return {
        type: IMPORT_ORG_PROPERITES_FAILURE,
        payload: error
    };
};

export const importOrgPropertiesSuccess = data => {
    return {
        type: IMPORT_ORG_PROPERITES_SUCCESS,
        payload: data
    };
};

export const addOrgProperty = payload => {
    return {
        type: ADD_ORG_PROPERITY,
        payload: payload
    };
};

// export const addOrgPropertyFailure = error => {
//     return {
//         type: ADD_ORG_PROPERITY_FAILURE,
//         payload: error
//     };
// };

// export const addOrgPropertySuccess = data => {
//     return {
//         type: ADD_ORG_PROPERITY_SUCCESS,
//         payload: data
//     };
// };

export const updateOrgProperty = property => ({
    type: UPDATE_ORG_PROPERTY,
    payload: property
});

export const updateOrgPropertySuccess = property => ({
    type: UPDATE_ORG_PROPERTY_SUCCESS,
    payload: property
});

export const updateOrgPropertyFailure = error => ({
    type: UPDATE_ORG_PROPERTY_FAILURE,
    payload: error
});

export const deleteProperty = payload => {
    return {
        type: DELETE_PROPERITY,
        payload: payload
    };
};

export const deletePropertyFailure = error => {
    return {
        type: DELETE_PROPERITY_FAILURE,
        payload: error
    };
};

export const deletePropertySuccess = propertyId => {
    return {
        type: DELETE_PROPERITY_SUCCESS,
        payload: propertyId
    };
};

export const getPropertyByIdCollection = propertyId => {
    return {
        type: GET_PROPERTY_BY_ID,
        propertyId: propertyId
    };
};

export const getPropertyByIdCollectionFailure = error => {
    return {
        type: GET_PROPERTY_BY_ID_FAILURE,
        payload: error
    };
};

export const getPropertyByIdCollectionSuccess = data => {
    return {
        type: GET_PROPERTY_BY_ID_SUCCESS,
        payload: data
    };
};

export const setActiveUserProperty = property => ({
    type: SET_ACTIVE_USER_PROPERTY,
    payload: property
});

export const setActiveUserPropertySuccess = propertyData => ({
    type: SET_ACTIVE_USER_PROPERTY_SUCCESS,
    payload: propertyData
});

export const setActiveUserPropertyFailure = error => ({
    type: SET_ACTIVE_USER_PROPERTY_FAILURE,
    payload: error
});

export const clearPropertyData = data => ({
    type: CLEAR_PROPERTY_DATA,
    payload: data
});

export const clearPropertyDataSuccess = () => ({
    type: CLEAR_PROPERTY_DATA_SUCCESS
});

export const clearPropertyDataFailure = error => ({
    type: CLEAR_PROPERTY_DATA_FAILURE,
    payload: error
});
