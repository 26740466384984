import {
    GET_EXISTING_MEMBER,
    SET_EXISTING_MEMBER,
    ADD_NEW_MEMBER,
    ADD_NEW_MEMBER_SUCCESS,
    ADD_NEW_MEMBER_FAILURE,
    RESET_ADD_NEW_MEMBER,
    CREATE_MEMBER_USER,
    CREATE_MEMBER_USER_SUCCESS,
    CREATE_MEMBER_USER_FAILURE,
    ADD_EDIT_MEMBER_DEVICE,
    ADD_EDIT_MEMBER_DEVICE_SUCCESS,
    ADD_EDIT_MEMBER_DEVICE_FAILURE,
    REMOVE_MEMBER_DEVICE,
    REMOVE_MEMBER_DEVICE_SUCCESS,
    REMOVE_MEMBER_DEVICE_FAILURE,
    UPDATE_REGISTER,
    UPDATE_REGISTER_SUCCESS,
    UPDATE_REGISTER_FAILURE,
    REMOVE_MEMBER_USER_SUCCESS,
    REMOVE_MEMBER_USER_FAILURE,
    TRANSFER_MEMBER,
    TRANSFER_MEMBER_SUCCESS,
    TRANSFER_MEMBER_FAILURE
} from '../actions/types';

const INITIAL_STATE = {
    loading: null,
    error: null,
    member: null,
    success: null,
    found: null,
    userCreated: null
};

const membersReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case GET_EXISTING_MEMBER:
            return {
                ...state,
                member: action.payload.member,
                loading: true
            };
        case SET_EXISTING_MEMBER:
            return {
                ...state,
                found: action.payload,
                loading: false
            };
        case ADD_NEW_MEMBER:
            return {
                ...state,
                // member: action.payload,
                loading: true
            };
        case ADD_NEW_MEMBER_SUCCESS:
            return {
                ...state,
                loading: null,
                error: null,
                success: true
            };
        case ADD_NEW_MEMBER_FAILURE:
            return {
                ...state,
                loading: null,
                error: action.payload
            };
        case ADD_EDIT_MEMBER_DEVICE:
            return {
                ...state,
                loading: true
            };
        case ADD_EDIT_MEMBER_DEVICE_SUCCESS:
            return {
                ...state,
                loading: null,
                error: null,
                success: true
            };
        case ADD_EDIT_MEMBER_DEVICE_FAILURE:
            return {
                ...state,
                loading: null,
                error: action.payload,
                success: false
            };
        case REMOVE_MEMBER_DEVICE:
            return {
                ...state,
                loading: true
            };
        case REMOVE_MEMBER_DEVICE_SUCCESS:
            return {
                ...state,
                loading: null,
                error: null,
                success: true
            };
        case REMOVE_MEMBER_DEVICE_FAILURE:
            return {
                ...state,
                loading: null,
                error: action.payload,
                success: false
            };
        case RESET_ADD_NEW_MEMBER:
            return {
                ...state,
                loading: null,
                error: null,
                found: null,
                member: action.payload === 'member' ? null : state.member,
                success: action.payload === 'member' ? null : state.success
            };
        case CREATE_MEMBER_USER:
            return {
                ...state,
                // member: action.payload,
                loading: true
            };
        case CREATE_MEMBER_USER_SUCCESS:
            return {
                ...state,
                loading: null,
                error: null,
                userCreated: true
            };
        case CREATE_MEMBER_USER_FAILURE:
            return {
                ...state,
                loading: null,
                error: action.payload
            };
        case REMOVE_MEMBER_USER_SUCCESS:
            return {
                ...state,
                loading: null,
                error: null,
                success: true
            };
        case REMOVE_MEMBER_USER_FAILURE:
            return {
                ...state,
                loading: null,
                error: action.payload
            };
        case TRANSFER_MEMBER:
            return {
                ...state,
                loading: true,
                error: null,
                success: null
            };
        case TRANSFER_MEMBER_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
                success: true
            };
        case TRANSFER_MEMBER_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload,
                success: false
            };

        case UPDATE_REGISTER:
            return {
                ...state,
                loading: true,
                error: null,
                success: null
            };
        case UPDATE_REGISTER_SUCCESS:
            return {
                ...state,
                loading: null,
                error: null,
                success: true
            };
        case UPDATE_REGISTER_FAILURE:
            return {
                ...state,
                loading: null,
                error: action.payload,
                success: null
            };
        default:
            return state;
    }
};

export default membersReducer;
