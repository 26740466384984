import React from 'react';
import PropTypes from 'prop-types';

// material-ui
import { Button, DialogContent, Stack, Typography } from '@mui/material';

// project import
import Avatar from 'components/@extended/Avatar';

// assets
import { DeleteFilled } from '@ant-design/icons';

// ==============================|| DELETE - MODAL||============================== //

export default function DeleteModal({ title, body, onClose, onDelete, custom }) {
    const handleDelete = () => {
        if (onDelete) {
            onDelete();
        }

        onClose();
    };

    // TODO: Create a reusable modal content component
    return (
        <>
            <DialogContent sx={{ mt: 2, my: 1 }}>
                <Stack alignItems="center" spacing={3.5}>
                    <Avatar
                        color="error"
                        sx={{ width: 72, height: 72, fontSize: '1.75rem' }}
                    >
                        <DeleteFilled />
                    </Avatar>
                    <Stack spacing={2}>
                        <Typography variant="h4" align="center">
                            {body}
                        </Typography>
                        <Typography align="center">
                            {custom && custom.prefix
                                ? custom.prefix
                                : `Do you really want to delete `}
                            <Typography
                                variant="subtitle1"
                                component="span"
                                textTransform={'capitalize'}
                            >
                                {' '}
                                &quot;{title}&quot;{' '}
                            </Typography>
                            {custom && custom.postfix
                                ? custom.postfix
                                : `? This action cannot be undone.`}
                        </Typography>
                    </Stack>

                    <Stack direction="row" spacing={2} sx={{ width: 1 }}>
                        <Button
                            fullWidth
                            onClick={onClose}
                            color="secondary"
                            variant="outlined"
                        >
                            Cancel
                        </Button>
                        <Button
                            fullWidth
                            color="error"
                            variant="contained"
                            onClick={handleDelete}
                            autoFocus
                        >
                            Delete
                        </Button>
                    </Stack>
                </Stack>
            </DialogContent>
        </>
    );
}

DeleteModal.propTypes = {
    title: PropTypes.string,
    body: PropTypes.string,
    open: PropTypes.bool,
    handleClose: PropTypes.func
};
