import {
    GET_DEVICE_OPERATIONS,
    GET_DEVICE_OPERATIONS_SUCCESS,
    GET_DEVICE_OPERATIONS_FAILURE,
    HANDLE_DEVICE,
    HANDLE_DEVICE_SUCCESS,
    HANDLE_DEVICE_FAILURE,
    GET_PROPERTY_DEVICES_SUCCESS,
    GET_PROPERTY_DEVICES_FAILURE,
    RESET_DEVICE_STATE,
    CHECK_DEVICE_EXISTS,
    SET_DEVICE_EXISTS_VALIDATION_RESULT
} from './types';

export const getDeviceOperations = () => {
    return {
        type: GET_DEVICE_OPERATIONS
    };
};

export const getDeviceOperationsSuccess = operations => {
    return {
        type: GET_DEVICE_OPERATIONS_SUCCESS,
        payload: operations
    };
};

export const getDeviceOperationsFailure = error => {
    return {
        type: GET_DEVICE_OPERATIONS_FAILURE,
        payload: error
    };
};

export const handleDevice = device => {
    return {
        type: HANDLE_DEVICE,
        payload: device
    };
};

export const handleDeviceSuccess = () => {
    return {
        type: HANDLE_DEVICE_SUCCESS
    };
};

export const handleDeviceFailure = () => {
    return {
        type: HANDLE_DEVICE_FAILURE
    };
};

export const resetDeviceState = () => {
    return {
        type: RESET_DEVICE_STATE
    };
};

// export const getPropertyDevices = id => {
//     return {
//         type: GET_PROPERTY_MEMBERS,
//         payload: id
//     };
// };

export const getPropertyDevicesSuccess = devices => {
    return {
        type: GET_PROPERTY_DEVICES_SUCCESS,
        payload: devices
    };
};

export const getPropertyDevicesFailure = error => {
    return {
        type: GET_PROPERTY_DEVICES_FAILURE,
        payload: error
    };
};

export const checkDeviceExists = (deviceNumber, orgId) => {
    return {
        type: CHECK_DEVICE_EXISTS,
        payload: { deviceNumber, orgId }
    };
};

export const setDeviceExistsValidationResult = (isValid, errorMessage) => {
    return {
        type: SET_DEVICE_EXISTS_VALIDATION_RESULT,
        payload: { isValid, errorMessage }
    };
};
