import React, { useEffect } from 'react';
import {
    Button,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    Grid,
    InputLabel,
    Stack,
    TextField,
    Tooltip
} from '@mui/material';
import { DeleteFilled } from '@ant-design/icons';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { MODAL_ACTION_TYPE } from '../../../utils/constants/modal';
import Select from '../../../components/common/Select';
import IconButton from '../../../components/@extended/IconButton';
import InputFieldErrorMessage from '../../../components/common/InputFieldErrorMessage';
import { resetTagMasterSuccessState } from '../../../store/actions/Org';

const CreateUpdateTagMasterCameraModal = ({
    camera,
    locations,
    modalType,
    onSubmit,
    onDelete,
    onClose
}) => {
    const dispatch = useDispatch();
    const cameraOperationSuccess = useSelector(state => state.org.cameraOperationSuccess);

    useEffect(() => {
        if (cameraOperationSuccess) {
            onClose();
            dispatch(resetTagMasterSuccessState);
        }
    }, [cameraOperationSuccess, onClose]);

    const initialValues = {
        ip_address: '',
        loc_id: '',
        type: 'tag-master-lpr',
        ...camera
    };

    const locationOptions = locations
        ? locations.map(location => ({
              key: location.id,
              value: location.name
          }))
        : [];

    const validationSchema = Yup.object().shape({
        ip_address: Yup.string().required('IP Address is required'),
        loc_id: Yup.string().required('Location is required')
    });

    const handleSubmit = values => {
        onSubmit(values);
    };

    const handleDelete = () => {
        onDelete(camera);
    };

    return (
        <>
            <DialogTitle>
                {modalType === MODAL_ACTION_TYPE.EDIT
                    ? 'Edit LPR Camera'
                    : 'Add New LPR Camera'}
            </DialogTitle>
            <Divider />
            <Formik
                initialValues={initialValues}
                onSubmit={handleSubmit}
                validationSchema={validationSchema}
                validateOnChange
            >
                {({
                    handleSubmit,
                    handleChange,
                    handleBlur,
                    touched,
                    errors,
                    values
                }) => (
                    <>
                        <DialogContent sx={{ p: 2.5 }}>
                            <Grid container columnSpacing={2}>
                                <Grid item xs={12} sm={12}>
                                    <Stack>
                                        <InputLabel htmlFor={'ip_address'}>
                                            IP Address
                                        </InputLabel>
                                        <TextField
                                            name="ip_address"
                                            required
                                            placeholder="IP Address"
                                            value={values.ip_address}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            error={
                                                touched.ip_address &&
                                                Boolean(errors.ip_address)
                                            }
                                            disabled={!onSubmit}
                                            fullWidth
                                        />
                                        <InputFieldErrorMessage
                                            touched={touched.ip_address}
                                            error={errors.ip_address}
                                        />
                                    </Stack>
                                </Grid>
                                <Grid item xs={12} sm={12}>
                                    <Stack>
                                        <InputLabel htmlFor={'loc_id'}>
                                            Location
                                        </InputLabel>
                                        <Select
                                            label={'Location'}
                                            name={'loc_id'}
                                            value={values.loc_id}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            touched={touched.loc_id}
                                            error={errors.loc_id}
                                            options={locationOptions}
                                            disabled={!onSubmit}
                                            required
                                        />
                                        <InputFieldErrorMessage
                                            touched={touched.loc_id}
                                            error={errors.loc_id}
                                        />
                                    </Stack>
                                </Grid>
                            </Grid>
                        </DialogContent>
                        <Divider />
                        <DialogActions sx={{ p: 2.5 }}>
                            <Grid
                                container
                                justifyContent="space-between"
                                alignItems="center"
                            >
                                <Grid item>
                                    {modalType === MODAL_ACTION_TYPE.EDIT && onDelete && (
                                        <Tooltip title="Delete Camera" placement="top">
                                            <IconButton
                                                size="large"
                                                color="error"
                                                onClick={handleDelete}
                                            >
                                                <DeleteFilled />
                                            </IconButton>
                                        </Tooltip>
                                    )}
                                </Grid>
                                <Grid item>
                                    <Stack
                                        direction="row"
                                        spacing={2}
                                        alignItems="center"
                                    >
                                        <Button color="error" onClick={onClose}>
                                            {onSubmit ? 'Cancel' : 'Close'}
                                        </Button>
                                        {onSubmit && (
                                            <Button
                                                type="submit"
                                                variant="contained"
                                                onClick={handleSubmit}
                                            >
                                                {modalType === MODAL_ACTION_TYPE.EDIT
                                                    ? 'Update'
                                                    : 'Save'}
                                            </Button>
                                        )}
                                    </Stack>
                                </Grid>
                            </Grid>
                        </DialogActions>
                    </>
                )}
            </Formik>
        </>
    );
};

export default CreateUpdateTagMasterCameraModal;
