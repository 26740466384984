import { Interval, DateTime } from 'luxon';
import { CA_OPERATIONS, shortMonthNames } from './constants';
import { Timestamp } from '@firebase/firestore';
import Papa from 'papaparse';
import { pickBy } from 'lodash';

export const timestampToDate = timestamp => {
    if (timestamp instanceof Timestamp) {
        return new Date(timestamp.seconds * 1000);
    }
    return null;
};

export const isoStringToDate = dateString => {
    if (typeof dateString === 'string' && !isNaN(Date.parse(dateString))) {
        return new Date(dateString);
    }
    return null;
};

///////////////// Roles and Permissions /////////////////

export const flattenPermissions = permissions => {
    const flattenedSet = new Set();

    permissions.forEach(permission => {
        flattenedSet.add(permission.key);
        permission.modules.forEach(module => flattenedSet.add(module));
    });

    return [...flattenedSet];
};

///////////////// Activity Thread /////////////////

const dateOrdinal = day => {
    return 31 === day || 21 === day || 1 === day
        ? 'st'
        : 22 === day || 2 === day
        ? 'nd'
        : 23 === day || 3 === day
        ? 'rd'
        : 'th';
};

export const formatThreadDate = timeStamp => {
    return `${DateTime.fromSeconds(timeStamp).toFormat('cccc, LLL d')}${dateOrdinal(
        DateTime.fromSeconds(timeStamp).day
    )}`;
};

export const formatOperationDate = timeStamp => {
    return `${DateTime.fromSeconds(timeStamp).toFormat('cccc, LLL d')}${dateOrdinal(
        DateTime.fromSeconds(timeStamp).day
    )} ${DateTime.fromSeconds(timeStamp).toFormat('yyyy')}`;
};

export const formatThreadTime = timeStamp => {
    return DateTime.fromSeconds(timeStamp).toFormat('t');
};

export const formatActivity = data => {
    const formattedEvents = [];
    const activity = [...data];
    const sortedEvents = activity.sort((x, y) => {
        return y.created_at.seconds - x.created_at.seconds;
    });
    sortedEvents.forEach(activity => {
        const activityDay = formatThreadDate(activity.created_at.seconds);

        const found = formattedEvents.findIndex(event => event.day === activityDay);

        if (found >= 0) {
            formattedEvents[found].events.push(activity);
        } else {
            formattedEvents.push({ day: activityDay, events: [{ ...activity }] });
        }
    });
    return formattedEvents;
};

export const generateUid = () => {
    const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let autoId = '';
    for (let i = 0; i < 20; i++) {
        autoId += chars.charAt(Math.floor(Math.random() * chars.length));
    }
    return autoId;
};

export const generateInviteCode = () => {
    const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    let invite_code = '';
    for (let i = 0; i < 6; i++) {
        invite_code += chars.charAt(Math.floor(Math.random() * chars.length));
    }
    return invite_code;
};

export const generateVerificationCode = () => {
    const chars = '0123456789';
    let verification_code = '';
    for (let i = 0; i < 6; i++) {
        verification_code += chars.charAt(Math.floor(Math.random() * chars.length));
    }
    return verification_code;
};

///////////////////////////////////////////////////

/////////////////// Documents /////////////////////

export const formatDocumentDate = timeStamp => {
    return `${DateTime.fromSeconds(timeStamp).toFormat('LLL d')}${dateOrdinal(
        DateTime.fromSeconds(timeStamp).day
    )}, ${DateTime.fromSeconds(timeStamp).toFormat('yyyy')}`;
};

///////////////////////////////////////////////////

////////////////////// Tasks //////////////////////

export const formatTaskDate = timeStamp => {
    return `${DateTime.fromSeconds(timeStamp).toFormat('LLL d')}${dateOrdinal(
        DateTime.fromSeconds(timeStamp).day
    )}, ${DateTime.fromSeconds(timeStamp).toFormat('h:mm')}${DateTime.fromSeconds(
        timeStamp
    )
        .toFormat('a')
        .toLocaleLowerCase()}
        `;
};

export const setTaskStatus = task => {
    const date = DateTime.fromSeconds(task.date_time.seconds);
    const diff = date.diffNow().as('hours');
    if (task.status === 'complete') return 'complete';
    if (diff < 0) {
        return 'danger';
    } else if (diff > 24) {
        return 'success';
    } else {
        return 'warning';
    }
};

export const tsFromJsDate = date => {
    return Timestamp.fromDate(date);
};

export const parseCheckInOutDateTime = (date, time) => {
    const [monthShort, monthDay, fullYear] = date.split(' ').slice(1);
    const { hours, minutes } = time;
    const monthNumber = shortMonthNames.indexOf(monthShort);
    return new Date(fullYear, monthNumber, monthDay, hours, minutes, 0);
};

export const getTimestampDifferenceInDays = (t1, t2) => {
    const difference = Math.abs(t1 - t2);
    return Math.floor(difference / (1000 * 60 * 60 * 24));
};

export const isDateUnavailable = date => {
    const today = new Date();
    const diff = getTimestampDifferenceInDays(date.getTime(), today.getTime());
    return date < today && diff >= 1;
};

///////////////////////////////////////////////////

////////////// Transaction List/Grid //////////////

export const formatCardDate = timeStamp => {
    return `${DateTime.fromSeconds(timeStamp).toFormat('LLL d')}${dateOrdinal(
        DateTime.fromSeconds(timeStamp).day
    )}`;
};

///////////////////////////////////////////////////

/////////////// Load FB Avatar Image //////////////

// Will revisit this to make sure we are doing the right thing for efficeincy
// export const setUserAvatar = (id) => {
//     const avatarRef = storage.ref().child(`users/${id}/avatar.jpg`);
//     avatarRef.getDownloadURL();
// };

///////////////////////////////////////////////////

//////////////////// Transactions //////////////////

export const getSources = (userSources, orgSources) => {
    if (orgSources && userSources) {
        return [...orgSources, ...userSources];
    } else if (orgSources) {
        return [...orgSources];
    } else if (userSources) {
        return [...userSources];
    } else {
        return [];
    }
};

export const daysOnMls = seconds => {
    const date = DateTime.fromSeconds(seconds);
    const days = Math.floor(Math.abs(date.diffNow().as('days')));
    return days;
};

export const daysTillClosing = seconds => {
    const date = DateTime.fromSeconds(seconds);
    const days = Math.ceil(Math.abs(date.diffNow().as('days')));
    return days;
};

export const parseTimeFromCSV = time => {
    const isAM = time.split(' ')[1] === 'AM';
    const timeArr = time.split(' ')[0].split(':');
    const [hours, minutes, seconds] = timeArr;
    const hoursIn24hFormat = {
        1: 13,
        2: 14,
        3: 15,
        4: 16,
        5: 17,
        6: 18,
        7: 19,
        8: 20,
        9: 21,
        10: 22,
        11: 23,
        12: 12
    };
    const res = [
        isAM
            ? Number.parseInt(hours) === 12
                ? 0
                : Number.parseInt(hours)
            : hoursIn24hFormat[hours],
        Number.parseInt(minutes),
        Number.parseInt(seconds)
    ];
    return res;
};

export const maskEmail = email => {
    const [name, domain] = email.split('@');
    const maskedName =
        name[0] +
        Array(name.length - 2)
            .fill('*')
            .join('') +
        name[name.length - 1];
    return maskedName + '@' + domain;
};

export const toSnakeCase = str =>
    str &&
    str
        .toLowerCase()
        .replace(/[^\w\s]|_/g, '')
        .replace(/\s+/g, '_');

///////////////////////////////////////////////////

////////////////// Twilio Video ///////////////////

// Checking Twilio Video Token Expiration
export const checkExpiration = seconds => {
    const invited_at = DateTime.fromSeconds(seconds);
    const currentTime = DateTime.now();
    const interval = Interval.fromDateTimes(invited_at, currentTime);
    if (interval.length('hours') >= 23) return true;
    return false;
};
///////////////////////////////////////////////////

export const capitalizeFirstLetter = str => {
    if (!str || str.trim().length === 0) return '';
    return str.charAt(0).toUpperCase() + str.slice(1);
};

export const capitalizeFirstLetterMulti = str => {
    if (!str || str.trim().length === 0) return '';
    return str
        .split(' ')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');
};

// let parties = formData.parties.reduce((partyObjects, party) => {
//     if (party.email !== '')
//         partyObjects.push({ email: party.email.toLowerCase(), id: null });
//     return partyObjects;
// }, []);

export const getDateFromTimestamp = timestamp => {
    const date = new Date(timestamp * 1000);
    let dd = date.getDate();
    let mm = date.getMonth() + 1;
    const yy = date.getFullYear().toString().substr(2, 2);
    const time = date.toLocaleTimeString([], {
        hour: '2-digit',
        minute: '2-digit'
    });

    if (dd < 10) {
        dd = '0' + dd;
    }

    if (mm < 10) {
        mm = '0' + mm;
    }

    return `${mm}-${dd}-${yy}, ${time}`;
};

export const generateNewOtcCode = () => {
    const chars = '0123456789';
    let inviteCode = '';
    for (let i = 0; i < 6; i++) {
        inviteCode += chars.charAt(Math.floor(Math.random() * chars.length));
    }
    return inviteCode;
};

export const parseFile = csvFile => {
    return new Promise(resolve => {
        const fileReader = new FileReader();
        fileReader.onload = e => {
            let csvText = e.target.result;
            csvText = csvText.replace(/^\s*[\r\n]/gm, '');
            Papa.parse(csvText, {
                header: true,
                dynamicTyping: true,
                skipEmptyLines: true,
                complete: result => {
                    resolve(result.data);
                }
            });
        };
        fileReader.readAsText(csvFile);
    });
};

export const flattenObject = (obj, parentKey = '') => {
    return Object.keys(obj).reduce((acc, key) => {
        const prefixedKey = parentKey ? `${parentKey}.${key}` : key;
        if (typeof obj[key] === 'object' && obj[key] !== null) {
            const flattened = flattenObject(obj[key], prefixedKey);
            return { ...acc, ...flattened };
        } else {
            return { ...acc, [prefixedKey]: obj[key] };
        }
    }, {});
};

export const cleanDocument = obj => pickBy(obj, v => v !== undefined);

export function getOperationString(operation) {
    let operationString;

    switch (operation) {
        case CA_OPERATIONS.ADD:
            operationString = 'added';
            break;
        case CA_OPERATIONS.REMOVE:
            operationString = 'deleted';
            break;
        case CA_OPERATIONS.EDIT:
            operationString = 'updated';
            break;
        default:
            operationString = 'unknown operation';
    }

    return operationString;
}

///////////// Dissect and prepare Property Address with potential Unit ////////////

export const preparePropertyAddress = (address, id) => {
    const matches = [...address.matchAll(/\b\d+\w*\b|\b\w\d*\b/g)];
    const address_1 = matches.length > 0 ? matches[0][0] : '';
    const address_2_match = address.match(
        /(?:#|Apt|Unit|Suite|Building|Lot|Floor|Room)\s*([\w-]+)/i
    );
    let address_2 = '';

    if (address_2_match) {
        address_2 = address_2_match[1];

        const additional_match = address.match(
            new RegExp(`${address_2}\\s+([A-Za-z0-9-]+)`, 'i')
        );
        if (additional_match) {
            address_2 += ` ${additional_match[1]}`;
        }
    } else {
        for (let i = 1; i < matches.length; i++) {
            if (isLikelyUnitNumber(matches[i][0], address)) {
                address_2 = matches[i][0];

                if (
                    i + 1 < matches.length &&
                    isLikelyUnitNumber(matches[i + 1][0], address)
                ) {
                    address_2 += ` ${matches[i + 1][0]}`;
                }
                break;
            }
        }
    }

    address_2 = cleanUnitNumber(address_2).replace(/\s+/g, '').trim().toLowerCase();

    return { address_1, address_2, address: address.trim(), id };
};

const isLikelyUnitNumber = (num, address) => {
    const streetWords = [
        'Street',
        'St',
        'Avenue',
        'Ave',
        'Road',
        'Rd',
        'Boulevard',
        'Blvd',
        'Lane',
        'Ln',
        'Drive',
        'Dr',
        'Circle',
        'Cir',
        'Court',
        'Ct',
        'Place',
        'Pl',
        'Terrace',
        'Ter',
        'Way',
        'Hwy',
        'Highway',
        'Parkway',
        'Pkwy',
        'Trail',
        'Trl',
        'Expressway',
        'Expwy',
        'Turnpike',
        'Tpke',
        'Alley',
        'Aly',
        'Cove',
        'Cv',
        'Square',
        'Sq'
    ];

    if (/\d+(st|nd|rd|th)/i.test(num)) {
        return false;
    }

    const streetRegex = new RegExp(`\\b${num}\\b\\s+(${streetWords.join('|')})`, 'i');

    return !streetRegex.test(address);
};

const cleanUnitNumber = unit => {
    return unit
        .split(' ')
        .filter(word => !/^[A-Za-z]{3,}$/.test(word))
        .join(' ');
};
