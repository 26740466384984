import {
    SET_USER_ORG,
    CREATE_ORG,
    CREATE_ORG_SUCCESS,
    CREATE_ORG_FAILURE,
    UPDATE_ORG,
    UPDATE_ORG_SUCCESS,
    UPDATE_ORG_FAILURE,
    RESET_ORG_UPDATE_STATE,
    GET_DEFAULT_ROLES_COLLECTION,
    GET_DEFAULT_ROLES_COLLECTION_SUCCESS,
    GET_DEFAULT_ROLES_COLLECTION_FAILURE,
    RESET_ORG_CREATION_STATE,
    SET_PRIMARY_ORG,
    ADD_INVITE_TO_ORG,
    ADD_INVITE_TO_ORG_SUCCESS,
    ADD_INVITE_TO_ORG_FAILURE,
    GET_ORGS_COLLECTION_SUCCESS,
    SET_SELECTED_ORG,
    RESET_INVITE_ADDING_STATE,
    UPDATE_ORG_IMAGE,
    UPDATE_ORG_IMAGE_SUCCESS,
    UPDATE_ORG_IMAGE_FAILURE,
    RESEND_MANAGER_INVITE,
    RESEND_MANAGER_INVITE_SUCCESS,
    RESEND_MANAGER_INVITE_FAILURE,
    VALIDATE_SHORT_CODE,
    SET_SHORT_CODE_VALIDATION_RESULT,
    ADD_TAG_MASTER_CAMERA,
    ADD_TAG_MASTER_CAMERA_SUCCESS,
    ADD_TAG_MASTER_CAMERA_FAILURE,
    UPDATE_TAG_MASTER_CAMERA,
    UPDATE_TAG_MASTER_CAMERA_SUCCESS,
    UPDATE_TAG_MASTER_CAMERA_FAILURE,
    DELETE_TAG_MASTER_CAMERA,
    DELETE_TAG_MASTER_CAMERA_SUCCESS,
    DELETE_TAG_MASTER_CAMERA_FAILURE,
    RESET_TAG_MASTER_SUCCESS_STATE
} from '../actions/types';

const INITIAL_STATE = {
    orgs: null,
    selectedOrg: null,
    defaultRoles: null,
    loadingDefaultRoles: false,
    defaultRolesLoadError: null,
    creatingOrg: false,
    orgCreationError: null,
    newOrgName: null,
    updatingOrg: false,
    orgUpdateError: null,
    updatedOrgName: null,
    primaryOrg: null,
    addingInvite: false,
    inviteAddingError: null,
    inviteAddingSuccess: false,
    uploadingOrgImage: false,
    uploadOrgImageSuccess: false,
    uploadOrgImageError: null,
    resendingInvite: false,
    resendingInviteSuccess: null,
    resendingInviteError: null,
    isShortCodeValid: null,
    shortCodeValidationError: null,
    shortCodeValidationChecked: false,
    addingCamera: false,
    cameraError: null,
    updatingCamera: false,
    deletingCamera: false,
    cameraOperationSuccess: false
};

const alphabetizedOrgs = orgs => {
    if (orgs.length > 1) return orgs.sort((a, b) => a.org_name.localeCompare(b.org_name));
    return orgs;
};

const orgReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case GET_ORGS_COLLECTION_SUCCESS:
        case SET_USER_ORG:
            return {
                ...state,
                orgs: alphabetizedOrgs(action.payload)
            };
        case CREATE_ORG:
            return {
                ...state,
                creatingOrg: true,
                newOrgName: null
            };
        case CREATE_ORG_SUCCESS:
            return {
                ...state,
                creatingOrg: false,
                newOrgName: action.payload
            };
        case CREATE_ORG_FAILURE:
            return {
                ...state,
                creatingOrg: false,
                orgCreationError: action.payload
            };
        case UPDATE_ORG:
            return {
                ...state,
                updatingOrg: true
            };
        case UPDATE_ORG_SUCCESS:
            return {
                ...state,
                updatingOrg: false,
                updatedOrgName: action.payload
            };
        case UPDATE_ORG_FAILURE:
            return {
                ...state,
                updatingOrg: false,
                orgUpdateError: action.payload
            };
        case RESET_ORG_UPDATE_STATE:
            return {
                ...state,
                updatingOrg: false,
                orgUpdateError: null,
                newOrgName: null
            };
        case RESET_ORG_CREATION_STATE:
            return {
                ...state,
                creatingOrg: false,
                orgCreationError: null,
                newOrgName: null
            };
        case SET_PRIMARY_ORG:
            return {
                ...state,
                primaryOrg: action.payload
            };
        case ADD_INVITE_TO_ORG:
            return {
                ...state,
                addingInvite: true,
                inviteAddingError: null,
                inviteAddingSuccess: false
            };
        case ADD_INVITE_TO_ORG_SUCCESS:
            return {
                ...state,
                addingInvite: false,
                inviteAddingError: null,
                inviteAddingSuccess: action.payload
            };
        case ADD_INVITE_TO_ORG_FAILURE:
            return {
                ...state,
                addingInvite: false,
                inviteAddingError: action.payload,
                inviteAddingSuccess: false
            };
        case GET_DEFAULT_ROLES_COLLECTION:
            return {
                ...state,
                defaultRoles: null,
                loadingDefaultRoles: true,
                defaultRolesLoadError: null
            };
        case GET_DEFAULT_ROLES_COLLECTION_SUCCESS:
            return {
                ...state,
                defaultRoles: action.payload,
                loadingDefaultRoles: false,
                defaultRolesLoadError: null
            };
        case GET_DEFAULT_ROLES_COLLECTION_FAILURE:
            return {
                ...state,
                defaultRoles: null,
                loadingDefaultRoles: false,
                defaultRolesLoadError: action.payload
            };
        case SET_SELECTED_ORG:
            return {
                ...state,
                selectedOrg: action.payload
            };
        case RESET_INVITE_ADDING_STATE:
            return {
                ...state,
                addingInvite: false,
                inviteAddingError: null,
                inviteAddingSuccess: false
            };
        case UPDATE_ORG_IMAGE:
            return {
                ...state,
                uploadingOrgImage: true,
                uploadOrgImageSuccess: false,
                uploadOrgImageError: null
            };
        case UPDATE_ORG_IMAGE_SUCCESS:
            return {
                ...state,
                uploadingOrgImage: false,
                uploadOrgImageSuccess: action.payload,
                uploadOrgImageError: null
            };
        case UPDATE_ORG_IMAGE_FAILURE:
            return {
                ...state,
                uploadingOrgImage: false,
                uploadOrgImageSuccess: false,
                uploadOrgImageError: action.payload
            };
        case RESEND_MANAGER_INVITE:
            return {
                ...state,
                resendingInvite: true
            };
        case RESEND_MANAGER_INVITE_SUCCESS:
            return {
                ...state,
                resendingInvite: false,
                resendingInviteSuccess: action.payload
            };
        case RESEND_MANAGER_INVITE_FAILURE:
            return {
                ...state,
                resendingInvite: false,
                resendingInviteError: action.payload
            };
        case VALIDATE_SHORT_CODE:
            return {
                ...state,
                isShortCodeValid: null,
                shortCodeValidationError: null
            };
        case SET_SHORT_CODE_VALIDATION_RESULT:
            return {
                ...state,
                isShortCodeValid: action.payload.isValid,
                shortCodeValidationChecked: true,
                shortCodeValidationError: action.payload.errorMessage
            };
        case ADD_TAG_MASTER_CAMERA:
            return {
                ...state,
                addingCamera: true,
                cameraOperationSuccess: false,
                cameraError: null
            };
        case ADD_TAG_MASTER_CAMERA_SUCCESS:
            return {
                ...state,
                addingCamera: false,
                cameraOperationSuccess: true,
                selectedOrg: {
                    ...state.selectedOrg,
                    hardware: [...(state.selectedOrg.hardware || []), action.payload]
                }
            };
        case ADD_TAG_MASTER_CAMERA_FAILURE:
            return { ...state, addingCamera: false, cameraError: action.payload };
        case UPDATE_TAG_MASTER_CAMERA:
            return {
                ...state,
                updatingCamera: true,
                cameraOperationSuccess: false,
                cameraError: null
            };
        case UPDATE_TAG_MASTER_CAMERA_SUCCESS:
            return {
                ...state,
                updatingCamera: false,
                cameraOperationSuccess: true,
                selectedOrg: {
                    ...state.selectedOrg,
                    hardware: state.selectedOrg.hardware.map(camera =>
                        camera.id === action.payload.id ? action.payload : camera
                    )
                }
            };
        case UPDATE_TAG_MASTER_CAMERA_FAILURE:
            return { ...state, updatingCamera: false, cameraError: action.payload };
        case DELETE_TAG_MASTER_CAMERA:
            return {
                ...state,
                deletingCamera: true,
                cameraOperationSuccess: false,
                cameraError: null
            };
        case DELETE_TAG_MASTER_CAMERA_SUCCESS:
            return {
                ...state,
                deletingCamera: false,
                cameraOperationSuccess: true,
                selectedOrg: {
                    ...state.selectedOrg,
                    hardware: state.selectedOrg.hardware.filter(
                        camera => camera.id !== action.payload
                    )
                }
            };
        case DELETE_TAG_MASTER_CAMERA_FAILURE:
            return { ...state, deletingCamera: false, cameraError: action.payload };
        case RESET_TAG_MASTER_SUCCESS_STATE:
            return {
                ...state,
                cameraOperationSuccess: false
            };
        default:
            return state;
    }
};

export default orgReducer;
