// React
import React, { useState, useEffect } from 'react';

// Assets
import { AudioOutlined, AudioMutedOutlined } from '@ant-design/icons';

// Packages
import {
    DisconnectButton,
    GridLayout,
    LiveKitRoom,
    ParticipantTile,
    RoomAudioRenderer,
    useTrackToggle,
    useTracks,
    useRoomContext
} from '@livekit/components-react';
import { createLocalAudioTrack, Track } from 'livekit-client';
import { Button, IconButton, Stack } from '@mui/material';

// Styles
import '@livekit/components-styles';

// Utils
import { drawerTypes } from 'utils/constants';

const serverUrl = 'wss://sage-9htia2aq.livekit.cloud';

// ==============================|| VIRTUAL GUARD ||============================== //

function MyVideoConference({
    localParticipant,
    endPanel,
    setDrawerType,
    setDrawerTitle
}) {
    const [remoteConnected, setRemoteConnected] = useState(null);
    const [disconnecting, setDisconnecting] = useState(false);
    const lkRoom = useRoomContext();

    const trackRefs = useTracks(
        [
            { source: Track.Source.Camera, withPlaceholder: true },
            { source: Track.Source.Microphone }
        ],
        {
            onlySubscribed: false
        }
    );

    const localParticipantAudioTrack = trackRefs.find(
        trackRef =>
            trackRef.source === 'microphone' &&
            trackRef?.participant.identity === localParticipant
    );

    const remoteCamTrackRef = trackRefs.filter(
        trackRef =>
            trackRef?.participant.identity !== localParticipant &&
            trackRef?.participant.identity !== '' &&
            trackRef.source === 'camera'
    );

    useEffect(() => {
        if (!remoteConnected) {
            if (remoteCamTrackRef && remoteCamTrackRef?.length) {
                setRemoteConnected(true);
            }
        }
        if (remoteConnected && !remoteCamTrackRef.length) {
            setDisconnecting(true);
        }
    }, [remoteConnected, setRemoteConnected, remoteCamTrackRef]);

    useEffect(() => {
        if (disconnecting && lkRoom && lkRoom?.state === 'connected') {
            // console.log('execute disconnection');
            lkRoom.disconnect();
        }
    }, [disconnecting, lkRoom]);

    const { buttonProps, enabled } = useTrackToggle(localParticipantAudioTrack || []);

    const handleLogVisitor = () => {
        setDrawerTitle(drawerTypes.visitor.title);
        setDrawerType(drawerTypes.visitor.type);
    };

    return (
        <>
            <Stack
                direction="row"
                width="max-content"
                sx={{
                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                    borderRadius: 10,
                    position: 'absolute',
                    p: '0 3px',
                    zIndex: 1,
                    bottom: '20px',
                    left: '50%',
                    transform: 'translateX(-50%)'
                }}
            >
                <Button
                    variant="contained"
                    color="primary"
                    size="large"
                    sx={{
                        margin: 'auto',
                        borderRadius: 10,
                        cursor: 'pointer'
                    }}
                    onClick={handleLogVisitor}
                >
                    Log Visitor
                </Button>
                <Stack
                    backgroundColor="white"
                    borderRadius="50%"
                    justifyContent="center"
                    sx={{
                        height: '40px',
                        margin: '3px 30px'
                    }}
                >
                    <IconButton
                        size="large"
                        sx={{
                            color: 'black'
                        }}
                        onClick={buttonProps.onClick}
                    >
                        {!enabled && (
                            <AudioMutedOutlined style={{ fontSize: '1.15rem' }} />
                        )}
                        {enabled && <AudioOutlined style={{ fontSize: '1.15rem' }} />}
                    </IconButton>
                </Stack>
                <DisconnectButton
                    style={{
                        margin: 'auto',
                        borderRadius: '40px'
                    }}
                    // onClick={endPanel}
                >
                    End Call
                </DisconnectButton>
            </Stack>
            <GridLayout tracks={remoteCamTrackRef}>
                <ParticipantTile />
            </GridLayout>
        </>
    );
}

const VideoCall = ({
    token,
    localParticipant,
    endPanel,
    setDrawerType,
    setDrawerTitle
}) => {
    const [audioTrack, setAudioTrack] = useState(null);

    useEffect(() => {
        const configureAudioTrack = async () => {
            const track = await createLocalAudioTrack({
                noiseSuppression: true,
                echoCancellation: true,
                autoGainControl: true
            });
            setAudioTrack(track);
        };

        configureAudioTrack();
    }, []);

    if (!audioTrack) {
        return <div>Loading...</div>;
    }

    return (
        <LiveKitRoom
            video={true}
            audio={true}
            token={token}
            serverUrl={serverUrl}
            data-lk-theme="default"
            onDisconnected={() => endPanel()}
            options={{
                adaptiveStream: false,
                publishDefaults: {
                    audioPreset: { maxBitrate: 128000 },
                    dtx: false
                }
            }}
        >
            <MyVideoConference
                localParticipant={localParticipant}
                endPanel={endPanel}
                setDrawerType={setDrawerType}
                setDrawerTitle={setDrawerTitle}
            />
            <RoomAudioRenderer />
        </LiveKitRoom>
    );
};

export default VideoCall;
