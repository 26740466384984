// React
import React from 'react';

// Packages
import {
    DialogContent,
    DialogTitle,
    Divider,
    Grid,
    Stack,
    Typography,
    Button
} from '@mui/material';

// Styles
import 'react-widgets/scss/styles.scss';
import { CA_OPERATIONS } from 'utils/constants';

const OperationDetailsModal = ({ onClose, operation, completeOperation }) => {
    console.log('operation', operation);
    const handleOperation = () => {
        if (operation.type === 'add') {
            operation.operation = CA_OPERATIONS.ADD;
        } else if (operation.type === 'remove') {
            operation.operation = CA_OPERATIONS.REMOVE;
        }
        completeOperation({ operation });
        onClose();
    };

    return (
        <>
            <DialogTitle
                sx={{ textTransform: 'capitalize' }}
            >{`${operation.type} Device Operation`}</DialogTitle>
            <Divider />
            <DialogContent sx={{ p: 2.5, height: 650 }}>
                <Grid container spacing={3}>
                    <Grid item xs={6}>
                        <Stack spacing={0.5}>
                            <>
                                <Typography align="left" variant="subtitle1">
                                    Continental Group Name:
                                </Typography>
                                <Typography
                                    align="left"
                                    variant="subtitle1"
                                    color="text.secondary"
                                >
                                    {operation.group_name}
                                </Typography>
                            </>
                            <>
                                <Typography align="left" variant="subtitle1">
                                    Access Group:
                                </Typography>
                                <Typography
                                    align="left"
                                    variant="subtitle1"
                                    color="text.secondary"
                                >
                                    {operation.access_group}
                                </Typography>
                            </>
                            <>
                                <Typography align="left" variant="subtitle1">
                                    Device Number:
                                </Typography>
                                <Typography
                                    align="left"
                                    variant="subtitle1"
                                    color="text.secondary"
                                >
                                    {operation.device_number}
                                </Typography>
                            </>
                            <>
                                <Typography align="left" variant="subtitle1">
                                    Device Type:
                                </Typography>
                                <Typography
                                    align="left"
                                    variant="subtitle1"
                                    color="text.secondary"
                                >
                                    {operation.device_type}
                                </Typography>
                            </>
                            <>
                                <Typography align="left" variant="subtitle1">
                                    Facility Code:
                                </Typography>
                                <Typography
                                    align="left"
                                    variant="subtitle1"
                                    color="text.secondary"
                                >
                                    {operation.facility_code}
                                </Typography>
                            </>
                            <>
                                <Typography align="left" variant="subtitle1">
                                    Resident:
                                </Typography>
                                <Typography
                                    align="left"
                                    variant="subtitle1"
                                    color="text.secondary"
                                >
                                    {operation.owner_name}
                                </Typography>
                            </>
                            <>
                                <Typography align="left" variant="subtitle1">
                                    Status:
                                </Typography>
                                <Typography
                                    align="left"
                                    variant="subtitle1"
                                    color={
                                        operation.status === 'pending'
                                            ? 'secondary'
                                            : operation.status === 'enabled'
                                            ? 'primary'
                                            : 'error'
                                    }
                                    textTransform={'uppercase'}
                                >
                                    {operation.status === 'pending'
                                        ? `(${operation.status})`
                                        : operation.status}
                                </Typography>
                            </>
                            <>
                                <Typography align="left" variant="subtitle1">
                                    Expires:
                                </Typography>
                                <Typography
                                    align="left"
                                    variant="subtitle1"
                                    color="text.secondary"
                                >
                                    {operation.expires_at}
                                </Typography>
                            </>
                            {operation?.vehicle && (
                                <>
                                    <Typography align="left" variant="subtitle1">
                                        Vehicle:
                                    </Typography>
                                    <Typography
                                        align="left"
                                        variant="subtitle1"
                                        color="text.secondary"
                                    >
                                        {`Color: ${operation.vehicle?.color}`}
                                    </Typography>
                                    <Typography
                                        align="left"
                                        variant="subtitle1"
                                        color="text.secondary"
                                    >
                                        {`Make: ${operation.vehicle?.make}`}
                                    </Typography>
                                    <Typography
                                        align="left"
                                        variant="subtitle1"
                                        color="text.secondary"
                                    >
                                        {`Model: ${operation.vehicle?.model}`}
                                    </Typography>
                                    <Typography
                                        align="left"
                                        variant="subtitle1"
                                        color="text.secondary"
                                    >
                                        {`State: ${operation.vehicle?.state}`}
                                    </Typography>
                                    <Typography
                                        align="left"
                                        variant="subtitle1"
                                        color="text.secondary"
                                    >
                                        {`Plate: ${operation.vehicle?.tag}`}
                                    </Typography>
                                    <Typography
                                        align="left"
                                        variant="subtitle1"
                                        color="text.secondary"
                                    >
                                        {`Year: ${operation.vehicle?.year}`}
                                    </Typography>
                                </>
                            )}
                        </Stack>
                    </Grid>
                    <Grid item sx={{ p: 0 }} xs={6}>
                        <Stack
                            sx={{
                                position: 'absolute',
                                bottom: '10%',
                                width: '45%',
                                p: 0
                            }}
                        >
                            <Stack
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    height: '100%',
                                    width: '100%'
                                }}
                            >
                                <Button
                                    sx={{ width: '100%' }}
                                    color={
                                        operation.type === 'edit'
                                            ? 'warning'
                                            : operation.type === 'add'
                                            ? 'primary'
                                            : 'error'
                                    }
                                    type="submit"
                                    variant="contained"
                                    onClick={handleOperation}
                                >
                                    {`Complete ${operation.type} Operation`}
                                </Button>
                            </Stack>
                        </Stack>
                    </Grid>
                </Grid>
            </DialogContent>
        </>
    );
};

export default OperationDetailsModal;
