import { all, fork, takeLatest, put, call } from 'redux-saga/effects';
import { rtdb } from 'config/firebase';
import { ref, get, update } from 'firebase/database';
import {
    FETCH_DEVICE_TYPES,
    ADD_DEVICE_TYPE,
} from '../actions/types';

import {
    fetchDeviceTypesSuccess,
    fetchDeviceTypesFailure,
    addDeviceTypeSuccess,
    addDeviceTypeFailure
} from '../actions/DeviceTypes';

const getDeviceTypesRef = () => ref(rtdb, 'device_types');

export function* fetchDeviceTypes() {
    try {
        const snapshot = yield call(get, getDeviceTypesRef());
        if (snapshot.exists()) {
            yield put(fetchDeviceTypesSuccess(snapshot.val()));
        } else {
            yield put(fetchDeviceTypesFailure('No device types found.'));
        }
    } catch (error) {
        yield put(fetchDeviceTypesFailure(error.message));
    }
}

export function* addDeviceType({ payload }) {
    try {
        const { key, value } = payload;
        const updates = {};
        updates[key] = value;
        yield call(update, getDeviceTypesRef(), updates);
        yield put(addDeviceTypeSuccess({ key, value }));
    } catch (error) {
        yield put(addDeviceTypeFailure(error.message));
    }
}

export function* watchFetchDeviceTypes() {
    yield takeLatest(FETCH_DEVICE_TYPES, fetchDeviceTypes);
}

export function* watchAddDeviceType() {
    yield takeLatest(ADD_DEVICE_TYPE, addDeviceType);
}

export default function* rootSaga() {
    yield all([fork(watchFetchDeviceTypes), fork(watchAddDeviceType)]);
}
