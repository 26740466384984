import React, { useMemo } from 'react';
import Table from '../../../components/common/Table';

const TagMasterCameraTable = ({ cameras, locations, onCameraClick }) => {
    const columns = useMemo(
        () => [
            {
                Header: 'IP Address',
                accessor: 'ip_address'
            },
            {
                Header: 'ID',
                accessor: 'id'
            },
            {
                Header: 'Location',
                accessor: 'loc_id',
                Cell: ({ value }) => {
                    const location = locations.find(loc => loc.id === value);
                    return location ? location.name : 'N/A';
                }
            }
        ],
        [locations]
    );

    return <Table columns={columns} data={cameras} onRowClick={onCameraClick} />;
};

export default TagMasterCameraTable;
